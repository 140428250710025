import { LOGGED_IN, LOGGED_OUT } from 'constants/types';

const initialState = {
  isLogged: false,
  token: null,
  user: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGGED_IN:
      return { ...state, ...payload, isLogged: true };
    case LOGGED_OUT:
      return { ...state, ...initialState };

    default:
      return state;
  }
};
