import { startLoading, stopLoading } from 'actions/Loading';
import { FETCH_USERS, SET_USER_ACTIONS } from 'constants/types';
import { errorHandler } from 'helpers/errorHandler';
import api from 'api';

export const fetchAllUsers = type => async dispatch => {
  dispatch(startLoading());
  try {
    const { data } = await api.get(type ? `/users?type=${type}` : '/users');

    dispatch({ type: FETCH_USERS, payload: data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const addUserCredits = (email, amount, type, transactionId) => async dispatch => {
  dispatch(startLoading());
  try {
    const { data } = await api.put(`/users/add-credits?type=${type}`, { email, amount, transactionId });

    dispatch({ type: FETCH_USERS, payload: data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const grantPrevilege = (email, isAdmin, type) => async dispatch => {
  dispatch(startLoading());
  try {
    const { data } = await api.put(`/users/grant-previlege?type=${type}`, { email, isAdmin });

    dispatch({ type: FETCH_USERS, payload: data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const banOrUnBanUser = (email, banned, type) => async dispatch => {
  dispatch(startLoading());
  try {
    const { data } = await api.put(`/users/ban-or-unban-user?type=${type}`, { email, banned });

    dispatch({ type: FETCH_USERS, payload: data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const getUserActions = userId => async dispatch => {
  dispatch(startLoading());
  dispatch({ type: SET_USER_ACTIONS, payload: undefined });
  try {
    const response = await api.get(`/admins/user-actions/${userId}`);

    dispatch({ type: SET_USER_ACTIONS, payload: response.data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
