/* eslint-disable max-lines */
import DetailsSubSection from 'components/Announcement/DetailsSubSection';
import PropTypes from 'prop-types';
import React from 'react';
import strings from 'constants/strings';

const AnnouncementDetails = ({
  announcementRef,
  category,
  subCategory,
  situation,
  surface,
  subActivity,
  activity,
  country,
  views,
  opportunity,
  subOpportunity,
  realEstateType,
  annualIncrease,
  region,
  address,
  incentives,
}) => {
  const renderVariableDetails = () => {
    if (activity || realEstateType)
      return (
        <>
          {activity ? (
            <>
              <DetailsSubSection title={strings.addAnnouncementActivityLabel} value={activity.title} />
              <DetailsSubSection title={strings.addAnnouncementSubActivityLabel} value={subActivity.title} />
            </>
          ) : (
            <DetailsSubSection title={strings.addAnnouncementRealEstateTypeLabel} value={realEstateType.title} />
          )}
          <DetailsSubSection title={strings.addAnnouncementSurfaceLabel} value={surface} />
          <DetailsSubSection title={strings.addAnnouncementSituationLabel} value={situation} />
        </>
      );
    if (opportunity)
      return (
        <>
          <DetailsSubSection title={strings.addAnnouncementOpportunityLabel} value={opportunity.title} />
          <DetailsSubSection title={strings.addAnnouncementSubOpportunityLabel} value={subOpportunity.title} />
          <DetailsSubSection title={strings.addAnnouncementIncentivesLabel} value={incentives} />
        </>
      );

    return null;
  };

  return (
    <>
      <div className="mb-3">
        <h2 className="h5 font-weight-bold ">{`${strings.informations}:`}</h2>
      </div>
      <div className="row">
        <div className="col-md-6">
          <ul className="list-unstyled font-size-1 mb-0">
            <DetailsSubSection title={strings.announcementID} value={announcementRef} />
            <DetailsSubSection title={strings.addAnnouncementCategoryLabel} value={category.title} />
            <DetailsSubSection title={strings.addAnnouncementSubCategoryLabel} value={subCategory.title} />
            <DetailsSubSection title={strings.addAnnouncementCountryLabel} value={country.title} />
            <DetailsSubSection title={strings.addAnnouncementRegionLabel} value={region.title} />
            {address && <DetailsSubSection title={strings.addAnnouncementAddressLabel} value={address} />}
          </ul>
        </div>

        <div className="col-md-6">
          <ul className="list-unstyled font-size-1 mb-0">
            {renderVariableDetails()}
            {annualIncrease && (
              <DetailsSubSection title={strings.addAnnouncementAnnualIncreaseLabel} value={`${annualIncrease}%`} />
            )}
          </ul>
        </div>
      </div>
      <div className="border-top border-bottom py-4 my-6">
        <div className="row justify-content-center">
          <div className="pr-md-4">
            <span className="text-primary ">{`${strings.announcementViewsPlural}:`}</span>
            <strong className="text-dark">
              {` ${views} ${views === 1 ? strings.announcementViewsSingular : strings.announcementViewsPlural}`}
            </strong>
          </div>
        </div>
      </div>
    </>
  );
};

AnnouncementDetails.propTypes = {
  announcementRef: PropTypes.string.isRequired,
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  subCategory: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  situation: PropTypes.string,
  surface: PropTypes.number,
  activity: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  subActivity: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  opportunity: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  subOpportunity: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  realEstateType: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  country: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  region: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  address: PropTypes.string,
  views: PropTypes.number.isRequired,
  annualIncrease: PropTypes.string,
  incentives: PropTypes.string,
};

export default AnnouncementDetails;
