import { useAppSelector } from 'hooks/useAppSelector';

export const useBreadcrumbs = () => {
  const categoryObject = useAppSelector(({ expert }) => ({
    category: expert.selectedExpert?.category,
    subCategory: expert.selectedExpert?.subCategory,
    name: expert.selectedExpert?.name,
  }));

  return categoryObject;
};
