/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import DisplayData from 'components/DisplayData';
import getImage from 'helpers/getImage';
import React from 'react';
import PropTypes from 'prop-types';
import { ExpertHeaderBreadCrumbs } from './ExpertHeaderBreadCrumbs';
import { ExtraDetails } from './ExtraDetails';
import { PublicationDate } from './PublicationDate';
import { useExpertHeader } from './useExpertHeader';

const ExpertHeader = ({ onClickScroll }) => {
  const { name, website, logo, phone, createdAt, address, description, email, country, region, keywords, teamLength } =
    useExpertHeader();

  return (
    <div className="main-raised space-1 mt-7">
      <div className="row px-5 no-gutters">
        <div className="col-md-3 col-12">
          <img src={getImage(logo)} alt={name} className="w-100 h-auto py-6" style={{ objectFit: 'contain' }} />
        </div>
        <div className="col-md-9 col-12 ">
          <div className="d-md-flex justify-content-between">
            <h1>
              <span className="h2 font-weight-bold">{name}</span>
              <ExpertHeaderBreadCrumbs country={country} region={region} />
            </h1>
            <PublicationDate createdAt={createdAt} />
          </div>
          <Divider />
          <div style={{ fontSize: '80%' }} className="text-muted font-font-weight-light">
            {keywords?.map(keyword => (
              <span key={keyword}>{`| ${keyword} `}</span>
            ))}
            |
          </div>
          <Divider />
          <ExtraDetails website={website} phone={phone} email={email} />
          <Divider />
          <h2 onClick={onClickScroll} className="font-font-weight-medium h5">{`Equipe (${teamLength}):`}</h2>

          <Divider />
          <h2 className="font-font-weight-medium h5">Description:</h2>
          <p>{description}</p>
          <Divider />
          <DisplayData className="text-primary" title="Adresse" value={address} />
        </div>
      </div>
    </div>
  );
};

ExpertHeader.propTypes = {
  onClickScroll: PropTypes.func,
};

export default ExpertHeader;

const Divider = () => <hr className="mt-1 mb-4" />;
