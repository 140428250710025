/* eslint-disable max-lines */
/* eslint-disable no-extra-parens */
/* eslint-disable react/no-array-index-key */
import { getUserActions } from 'actions/Users';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import Loader from 'components/Loader';

const getActionContent = ({ actionType, method, date, money, amount, status }) => {
  let iconName = '';

  let title = '';

  let iconBg = '';

  let content = '';

  let titleColor = 'dark';
  const dateFormatted = moment(date).format('LLLL');

  switch (actionType) {
    case 'TRANSACTION':
      iconName = 'money-bill-wave';
      title = 'Transaction';
      content = `Ravitaillement de ${amount} crédits`;
      if (method === 'ADMIN') {
        title += ` (Par administrateur)`;
        titleColor = 'success';
        iconBg = 'soft-success';
      } else {
        content += ` (${money / 1000} TND)`;
        switch (status) {
          case 'DECLINED':
            title += ` refusée`;
            titleColor = 'danger';
            iconBg = 'soft-danger';
            break;
          case 'ON_STAND_BY':
            title += ` en attente`;
            titleColor = 'warning';
            iconBg = 'soft-warning';
            break;
          case 'SUCCESS':
            title += ` effectuée`;
            titleColor = 'success';
            iconBg = 'soft-success';
            break;
          default:
            break;
        }
      }
      content += ` effectué le ${dateFormatted}.`;
      break;
    case 'ANNOUNCEMENT_BOOST':
      iconName = 'rocket';
      title = `Sponsorisation d'annonce`;
      content = `Sponsorisation de l'annonce :announcementTitle (${amount} crédits) effectuée le ${dateFormatted}.`;
      iconBg = 'soft-info';
      break;
    case 'ANNOUNCEMENT_CREATION':
      iconName = 'bullhorn';
      title = `Création d'annonce`;
      content = `Création de l'annonce :announcementTitle effectuée le ${dateFormatted}.`;
      iconBg = 'soft-success';

      break;
    case 'ANNOUNCEMENT_UNLOCK':
      iconName = 'eye';
      title = `Dévoilement d'annonce`;
      content = `Dévoilement de l'annonce :announcementTitle (${amount} crédits) effectué le ${dateFormatted}.`;
      iconBg = 'soft-info';

      break;
    case 'ACCOUNT_CREATION':
      iconName = 'user';
      title = `Création de compte`;
      content = `Création de compte effectuée le ${dateFormatted}.`;
      iconBg = 'soft-warning';

      break;
    default:
      break;
  }

  return {
    iconName,
    title,
    content,
    iconBg,
    titleColor,
  };
};

const UserAction = ({ action: { date, actionType, announcementTitle, amount, status, method, money } }) => {
  const { iconName, title, titleColor, iconBg, content } = useMemo(
    () => getActionContent({ actionType, method, date, money, amount, status }),
    [actionType, method, date, money, amount, status],
  );

  return (
    <div className="mb-2">
      <span className={`font-weight-bold text-${titleColor}`}>
        <div className={`btn btn-sm btn-${iconBg} mr-2`}>
          <i className={`fas fa-${iconName}`} />
        </div>
        {`${title} : `}
      </span>
      <span>
        {actionType.startsWith('ANNOUNCEMENT_') ? (
          <>
            {`${content.split(':announcementTitle')[0]} `}
            <strong>{announcementTitle}</strong>
            {` ${content.split(':announcementTitle')[1]}`}
          </>
        ) : (
          content
        )}
      </span>
    </div>
  );
};

UserAction.propTypes = {
  action: PropTypes.object.isRequired,
};

const UserDetails = () => {
  const {
    params: { id: userId },
  } = useRouteMatch();
  const dispatch = useDispatch();

  const { selectedUserActions } = useSelector(({ usersReducer }) => usersReducer);

  useEffect(() => {
    dispatch(getUserActions(userId));
  }, [dispatch, userId]);

  if (!selectedUserActions) return <Loader />;

  const {
    user: { fullName },
    actions,
  } = selectedUserActions;

  return (
    <div className="container pb-10 ">
      <h3 className="mb-5 mt-3">
        {`Historique d'activités de `}
        <strong>{fullName}</strong>
      </h3>
      {actions.map((action, index) => (
        <UserAction {...{ action }} key={`user-action-${index}`} />
      ))}
    </div>
  );
};

export default UserDetails;
