/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Annonce from 'pages/Annonce';
import AnnoncesList from 'pages/AnnoncesList';
import EditAnnouncement from 'pages/EditAnnouncement';
import HomePage from 'pages/HomePage';
import Loader from 'components/Loader';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import Sidebar from 'components/Sidebar';
import { checkLoggingStatus } from 'actions/auth';
import UserManagement from 'pages/UserManagement';
import VerifiedAnnouncements from 'pages/VerifiedAnnouncements';
import UserList from 'pages/UserList';
import UserDetails from 'pages/UserDetails';
import ValidateExperts from 'pages/ValidateExperts';
import ExpertPage from 'pages/ExpertPage';
import ExpertsList from 'pages/ExpertsList';

const App = () => {
  const dispatch = useDispatch();
  const { appInitialized } = useSelector(({ loading }) => loading);
  const { isLogged } = useSelector(({ auth }) => auth);
  const location = useLocation();

  useEffect(() => {
    dispatch(checkLoggingStatus());

    return () => {};
  }, []);
  const isDashboardLink =
    [
      '/',
      '/ban-user',
      '/validate-annoucements',
      '/add-credits',
      '/upgrade-role',
      '/downgrade-role',
      '/unban-user',
      '/annonces',
      '/users',
      '/validate-experts',
      '/experts',
      '/expert',
    ].includes(location.pathname) ||
    ['annonce', 'modifier-annonce', 'user', 'expert', 'experts'].includes(location.pathname.split('/')[1]);

  if (!appInitialized) return <Loader loading />;

  if (isDashboardLink && appInitialized && !isLogged) return <Redirect to="/sign-in" />;

  return (
    <div>
      <Switch>
        <Route exact path="/sign-in" component={Login} />
        {isDashboardLink && (
          <Sidebar>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/modifier-annonce/:slug" component={EditAnnouncement} />
            <Route exact path="/validate-annoucements" component={AnnoncesList} />
            <Route exact path="/validate-experts" component={ValidateExperts} />
            <Route exact path="/experts" component={ExpertsList} />
            <Route exact path="/expert/:slug" component={ExpertPage} />
            <Route exact path="/annonce/:slug" component={Annonce} />
            <Route exact path="/add-credits" component={UserManagement} />
            <Route exact path="/ban-user" component={UserManagement} />
            <Route exact path="/upgrade-role" component={UserManagement} />
            <Route exact path="/downgrade-role" component={UserManagement} />
            <Route exact path="/unban-user" component={UserManagement} />
            <Route exact path="/annonces" component={VerifiedAnnouncements} />
            <Route exact path="/users" component={UserList} />
            <Route exact path="/user/:id" component={UserDetails} />
          </Sidebar>
        )}
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default App;
