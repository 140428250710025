import { FETCH_TRANSACTIONS, FETCH_STATS } from 'constants/types';

const initialState = {
  transactions: [],
  stats: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TRANSACTIONS:
      return { ...state, transactions: [...payload] };
    case FETCH_STATS:
      return { ...state, stats: payload };

    default:
      return state;
  }
};
