/* eslint-disable max-len */
import AuthSidebar from 'components/AuthSidebar';
import FacebookTypes from 'constants/facebookTypes';
import LoginForm from 'containers/LoginForm';
import React from 'react';
import SEO from 'components/SEO';
import { login } from 'actions/auth';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = values => {
    dispatch(login(values, history));
  };

  return (
    <>
      <SEO title="Se connecter à elbusines.com" description="la page d'accueil du site" type={FacebookTypes.article} />
      <main id="content" role="main">
        <div className="d-flex align-items-center position-relative height-lg-100vh">
          <AuthSidebar />
          <div className="container">
            <div className="row no-gutters">
              <div className="ccol-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-6 offset-xl-5 space-3 space-lg-0">
                <LoginForm onSubmit={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;
