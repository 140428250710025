import { APP_INIT, LOGGED_IN, LOGGED_OUT } from 'constants/types';
import api, { db, resetAuthToken } from 'api';
import { startLoading, stopLoading } from 'actions/Loading';
import { errorHandler } from 'helpers/errorHandler';

export const checkLoggingStatus = () => async dispatch => {
  dispatch(startLoading());
  try {
    const id = window.localStorage.getItem('id');

    if (!id) throw Error('disconnected');
    else {
      const { token, userData } = await db.user.get({ id });

      await resetAuthToken(token.refreshToken);

      const newAccessToken = await refreshToken();

      dispatch({
        type: LOGGED_IN,
        payload: {
          token: { ...token, accessToken: newAccessToken },
          user: userData,
        },
      });
      await db.user.put({
        id,
        token: { ...token, accessToken: newAccessToken },
        userData,
      });
      await resetAuthToken(newAccessToken);
    }
  } catch (error) {
    dispatch({ type: LOGGED_OUT });
  }

  dispatch(stopLoading());
  dispatch({ type: APP_INIT });
};

export const login = ({ email, password }, history) => async dispatch => {
  dispatch(startLoading());
  try {
    const signInData = await api.post('/admins/sign-in', { email, password });

    const { token, ...userData } = signInData.data;

    resetAuthToken(token.accessToken);
    await db.user.put({ id: userData._id, token, userData });

    window.localStorage.setItem('id', userData._id);

    dispatch({ type: LOGGED_IN, payload: { token, user: userData } });

    history.push('/');
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const logout = () => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const {
      auth: { user },
    } = getState();

    resetAuthToken(null);
    window.localStorage.removeItem('id');

    await db.user.delete(user._id);
    dispatch({ type: LOGGED_OUT });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const refreshToken = async () => {
  try {
    const response = await api.get('/users/refresh-token');

    return response.data;
  } catch (error) {
    return false;
  }
};
