import moment from 'moment';
import calculateUserCredit from './calculateUserCredit';
import formatToCsv from './formatToCsv';

const formatUsersToCSV = users =>
  formatToCsv(users, [
    {
      label: 'Nom Complet',
      value: 'firstName',
      transform: (_firstName, row) => `${row.firstName} ${row.lastName}`,
    },
    {
      label: 'N° Téléphone',
      value: 'phoneNumber',
    },
    {
      label: 'Adresse e-mail',
      value: 'email',
    },
    {
      label: 'Entreprise',
      value: 'companyName',
    },
    {
      label: 'Pays',
      value: 'country',
    },
    {
      label: 'Crédits',
      value: 'credits',
      transform: credits => calculateUserCredit(credits),
    },
    {
      label: 'Créé le',
      value: 'createdAt',
      transform: createdAt => moment(createdAt).format('LL'),
    },
  ]);

export default formatUsersToCSV;
