/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import React from 'react';
import categoryNames from 'constants/categoryNames';
import formatPrice from 'helpers/formatPrice';
import strings from 'constants/strings';

const ExtraFieldsHeader = ({ title, intervals }) => {
  const colSize = Math.floor(12 / (intervals.length + 1));

  return (
    <div className="row flex-nowrap align-items-center my-2">
      <span className={`col-md-${colSize}  font-weight-bold `}>{title}</span>
      {intervals.map(el => (
        <span className={`col-md-${colSize} text-right font-weight-bold text-primary `} key={el}>
          {el}
        </span>
      ))}
    </div>
  );
};

const ExtraFieldsContent = ({ title, intervals, valueName, extraData }) => {
  const colSize = Math.floor(12 / (intervals.length + 1));

  return (
    <div className="row flex-nowrap align-items-center my-2">
      <span className={`col-md-${colSize} font-weight-bold`}>{title}</span>
      {intervals.map(el => (
        <span className={`col-md-${colSize} text-right`} key={title + el}>
          {extraData[el][valueName] ? formatPrice(extraData[el][valueName]) : '-'}
        </span>
      ))}
    </div>
  );
};

const ExtraDataFieldsSection = ({ selectedCategory, extraData }) => {
  const intervalsArray = Object.keys(extraData);

  switch (selectedCategory) {
    case categoryNames.CATEGORY_ENTREPRISE:
      return (
        <>
          <ExtraFieldsHeader title="Rubriques" intervals={intervalsArray} />
          <ExtraFieldsContent
            extraData={extraData}
            title={strings.extraDataFieldsTitles.chiffresAffaires}
            intervals={intervalsArray}
            valueName="chiffresAffaires"
          />
          <ExtraFieldsContent
            extraData={extraData}
            title="Charges d’exploitations"
            intervals={intervalsArray}
            valueName="chargesExploitation"
          />
          <ExtraFieldsContent
            extraData={extraData}
            title={strings.extraDataFieldsTitles.chargesPersonnel}
            intervals={intervalsArray}
            valueName="chargesPersonnel"
          />
          <ExtraFieldsContent
            extraData={extraData}
            title={strings.extraDataFieldsTitles.resultatNet}
            intervals={intervalsArray}
            valueName="resultatNet"
          />
          <ExtraFieldsContent
            extraData={extraData}
            title={strings.extraDataFieldsTitles.nombrePersonnel}
            intervals={intervalsArray}
            valueName="nombrePersonnel"
          />
        </>
      );

    case categoryNames.CATEGORY_IMMOBILIER:
      return (
        <>
          <ExtraFieldsHeader title="Rubriques" intervals={intervalsArray} />
          <ExtraFieldsContent
            extraData={extraData}
            title={strings.extraDataFieldsTitles.revenus}
            intervals={intervalsArray}
            valueName="revenus"
          />
          <ExtraFieldsContent
            extraData={extraData}
            title={strings.extraDataFieldsTitles.charges}
            intervals={intervalsArray}
            valueName="charges"
          />
        </>
      );

    case categoryNames.CATEGORY_INVESTISSEMENT:
      return (
        <>
          <ExtraFieldsHeader title="Rubriques" intervals={intervalsArray} />
          <ExtraFieldsContent
            extraData={extraData}
            title={strings.extraDataFieldsTitles.capitalSocial}
            intervals={intervalsArray}
            valueName="capitalSocial"
          />
          <ExtraFieldsContent
            extraData={extraData}
            title={strings.extraDataFieldsTitles.totalBilan}
            intervals={intervalsArray}
            valueName="totalBilan"
          />
          <ExtraFieldsContent
            extraData={extraData}
            title={strings.extraDataFieldsTitles.chiffresAffaires}
            intervals={intervalsArray}
            valueName="chiffresAffaires"
          />
          <ExtraFieldsContent
            extraData={extraData}
            title={strings.extraDataFieldsTitles.chargesExploitation}
            intervals={intervalsArray}
            valueName="chargesExploitation"
          />
          <ExtraFieldsContent
            extraData={extraData}
            title={strings.extraDataFieldsTitles.chargesPersonnel}
            intervals={intervalsArray}
            valueName="chargesPersonnel"
          />
          <ExtraFieldsContent
            extraData={extraData}
            title={strings.extraDataFieldsTitles.resultatNet}
            intervals={intervalsArray}
            valueName="resultatNet"
          />
          <ExtraFieldsContent
            extraData={extraData}
            title={strings.extraDataFieldsTitles.nombrePersonnel}
            intervals={intervalsArray}
            valueName="nombrePersonnel"
          />
        </>
      );
    default:
      return null;
  }
};

const ExtraDataFields = ({ selectedCategory, extraData, extraInfo }) => {
  return (
    <div className="pb-4 border-bottom">
      <div className="mb-3">
        <h2 className="h5 font-weight-bold">{`${strings.announcementMoreInformations} :`}</h2>
      </div>
      <div className="d-flex flex-column font-size-1 ">
        <ExtraDataFieldsSection extraData={extraData} selectedCategory={selectedCategory} />
        {extraInfo && (
          <div className="d-flex py-1 mt-4">
            <span className="font-weight-bold text-justify ">
              {`${strings.extraDataFieldsTitles.extraInfo}: `}
              <span className="text-primary font-weight-normal">{extraInfo}</span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

ExtraDataFields.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  extraInfo: PropTypes.string,
  extraData: PropTypes.object.isRequired,
};
ExtraDataFieldsSection.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  extraData: PropTypes.object.isRequired,
};

ExtraFieldsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  intervals: PropTypes.array.isRequired,
};

ExtraFieldsContent.propTypes = {
  title: PropTypes.string.isRequired,
  intervals: PropTypes.array.isRequired,
  valueName: PropTypes.string.isRequired,
  extraData: PropTypes.object.isRequired,
};

export default ExtraDataFields;
