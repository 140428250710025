import { useAppSelector } from 'hooks/useAppSelector';

export const useExpertHeader = () => {
  const headerObject = useAppSelector(({ expert: { selectedExpert } }) =>
    selectedExpert
      ? {
          name: selectedExpert.name,
          website: selectedExpert.website,
          logo: selectedExpert.logo[0],
          phone: selectedExpert.phone,
          createdAt: selectedExpert.createdAt,
          address: selectedExpert.address,
          description: selectedExpert.description,
          email: selectedExpert.email,
          country: selectedExpert.country,
          region: selectedExpert.region,
          keywords: selectedExpert.keywords,
          teamLength: selectedExpert.team?.length || 0,
        }
      : {},
  );

  return headerObject;
};
