import { get } from 'lodash';

const formatToCsv = (rawData, selector) => {
  const formattedData = [selector.map(el => el.label)];

  rawData.forEach((element, index) => {
    formattedData[index + 1] = selector.map(
      (el, i) => selector[i].transform?.(get(element, el.value), element) || get(element, el.value),
    );
  });

  return formattedData;
};

export default formatToCsv;
