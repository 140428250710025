/* eslint-disable no-unused-expressions */

import { useSelector } from 'react-redux';

export const useValidateExperts = () => {
  const pageObject = useSelector(({ expertList }) => ({
    page: expertList.page,
    totalPages: expertList.totalPages,
    count: expertList.totalItems,
    totalCount: expertList.totalCount,
  }));

  return { pageObject };
};
