import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { fetchAllUsers } from 'actions/Users';
import CachedIcon from '@material-ui/icons/Cached';
import PropTypes from 'prop-types';
import filterItems from 'pages/UserManagement/filterItems';
import { useHistory } from 'react-router';
import { CSVLink } from 'react-csv';
import formatUsersToCSV from 'helpers/formatUsersToCSV';
import columns from './columns';

const TableHeader = ({ keyword, setKeyword, onRefresh, clients }) => (
  <div className="d-flex">
    <span className="d-flex pr-4 justify-content-between align-items-center">Recherchez dans la liste</span>
    <div className="input-group">
      <input
        onChange={e => setKeyword(e.target.value)}
        value={keyword}
        type="text"
        name="filter"
        className="form-control"
      />
      <div onClick={() => setKeyword('')} className="input-group-append">
        <span className="input-group-text  bg-primary text-white" id="basic-addon2">
          X
        </span>
      </div>
    </div>
    <button className="btn  btn-outline-primary btn-icon  ml-4" type="button" onClick={onRefresh}>
      <CachedIcon />
    </button>
    <CSVLink
      className="btn  btn-outline-primary btn-icon  ml-2 d-flex align-items-center justify-content-center"
      filename="Utilisateurs.csv"
      data={formatUsersToCSV(clients)}
    >
      <i className="far fa-save" />
    </CSVLink>
  </div>
);

TableHeader.propTypes = {
  keyword: PropTypes.string,
  setKeyword: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  clients: PropTypes.array.isRequired,
};

const UserList = () => {
  const [keyword, setKeyword] = useState('');
  const { clients, loading } = useSelector(state => ({
    clients: state.usersReducer.clients,
    loading: state.loading.loading,
  }));
  const history = useHistory();
  const dispatch = useDispatch();

  const onRefresh = useCallback(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  const onUserSelect = useCallback(
    userId => {
      history.push(`/user/${userId}`);
    },
    [history],
  );

  return (
    <div>
      <DataTable
        data={filterItems(clients, keyword)}
        columns={columns(onUserSelect)}
        progressPending={loading}
        title="Liste des utilisateurs"
        noHeader
        subHeader
        subHeaderComponent={
          <TableHeader keyword={keyword} setKeyword={setKeyword} onRefresh={onRefresh} clients={clients} />
        }
        pagination
      />
    </div>
  );
};

export default UserList;
