// import SafeAreaView from 'components/SafeAreaView';
import ExpertListContainer from 'containers/ExpertListContainer';
import React from 'react';
import { useValidateExperts } from './useValidateExperts';

const ValidateExperts = () => {
  const { pageObject } = useValidateExperts();

  return (
    <div className="container position-relative">
      <h1 className="space-top-1">Liste des derniers experts et prestataires référencés non validé</h1>
      <span className="font-size-1 text-black-50 ml-1">{` (${pageObject.count || 0}) Experts trouvés`}</span>
      {pageObject.count ? (
        <ExpertListContainer />
      ) : (
        <>
          <h2>Aucun expert n&apos;est en attente </h2>
        </>
      )}
    </div>
  );
};

export default ValidateExperts;
