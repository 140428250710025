import PropTypes from 'prop-types';
import React from 'react';
import getImage from 'helpers/getImage';

const AvatarPlaceholder = ({ avatar, firstName, large = false }) => {
  const className = `position-relative rounded-circle ${large ? 'u-xl-avatar' : 'u-avatar'}`;

  if (avatar) return <img className={className} src={getImage(avatar)} alt="Contact" />;

  return (
    <span className={`btn btn-primary ${className}`}>
      <span className={`btn-icon__inner text-white ${large ? 'h1' : 'h6'}`}>{firstName && firstName[0]}</span>
    </span>
  );
};

AvatarPlaceholder.propTypes = {
  avatar: PropTypes.string,
  firstName: PropTypes.string,
  large: PropTypes.bool,
};

export default AvatarPlaceholder;
