import React from 'react';
import { useExpertList } from './useExpertsList';

const ExpertsList = () => {
  const { pageObject } = useExpertList();

  return (
    <div className="container position-relative">
      <h1 className="space-top-1">Liste des experts et prestataires référencés</h1>
      <span className="font-size-1 text-black-50 ml-1">{` (${pageObject.count || 0}) Experts trouvés`}</span>
      {/* {pageObject.count ? (
    <ExpertListContainer />
  ) : (
    <>
      <h2>Aucun expert n&apos;est en attente </h2>
    </>
  )} */}
    </div>
  );
};

export default ExpertsList;
