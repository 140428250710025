/* eslint-disable max-lines */
import React from 'react';
import MostStatCard from 'components/MostStatCard';
import StatCardElement from 'components/MostStatCard/StatCardElement';
import useTransactions from 'hooks/useTransactions';
import Collapsible from 'components/Collapsible';
import getImage from 'helpers/getImage';
import { CSVLink } from 'react-csv';
import formatAnnouncementsToCSV from 'helpers/formatAnnouncementsToCSV';
import formatTopUsersToCSV from 'helpers/formatTopUsersToCSV';

const StaticRecords = () => {
  const { mostViewdAnnouncement, mostUnlockedAnnouncements, mostSpendingUsers, mostBuyingUsers } = useTransactions();

  return (
    <div className="row">
      <div className="col-6 p-4">
        {mostViewdAnnouncement && (
          <CSVLink
            className="btn btn-block bt btn-soft-primary btn-sm mb-1"
            filename="Les annonces les plus visitées.csv"
            data={formatAnnouncementsToCSV(mostViewdAnnouncement)}
          >
            <i className="far fa-save mr-1" />
            Exporter en tant que CSV
          </CSVLink>
        )}
        <MostStatCard>
          <div className="py-2">
            <Collapsible title="Les annonces les plus visitées" noLine>
              {mostViewdAnnouncement &&
                mostViewdAnnouncement.map(o => (
                  <StatCardElement
                    key={o._id}
                    subtitle={`Ref= ${o.announcementRef}`}
                    image={getImage(o.photos[0])}
                    title={o.title}
                    iconOneFontAwsome="fa fa-eye"
                    iconOneText={`vu ${o.views} fois`}
                    iconTwoFontAwsome="fa fa-unlock"
                    iconTwoText={`dévoilé ${o.numberOfUnlocks} fois`}
                  />
                ))}
            </Collapsible>
          </div>
        </MostStatCard>
      </div>
      <div className="col-6 p-4">
        {mostUnlockedAnnouncements && (
          <CSVLink
            className="btn btn-block bt btn-soft-primary btn-sm mb-1"
            filename="Les annonces les plus dévoilées.csv"
            data={formatAnnouncementsToCSV(mostUnlockedAnnouncements)}
          >
            <i className="far fa-save mr-1" />
            Exporter en tant que CSV
          </CSVLink>
        )}
        <MostStatCard>
          <div className="py-2">
            <Collapsible title="Les annonces les plus dévoilées" noLine>
              {mostUnlockedAnnouncements &&
                mostUnlockedAnnouncements.map(o => (
                  <StatCardElement
                    key={o._id}
                    image={getImage(o.photos[0])}
                    subtitle={`Ref= ${o.announcementRef}`}
                    title={o.title}
                    iconTwoFontAwsome="fa fa-eye"
                    iconTwoText={`vu ${o.views} fois`}
                    iconOneFontAwsome="fa fa-unlock"
                    iconOneText={`dévoilé ${o.occurrence} fois`}
                  />
                ))}
            </Collapsible>
          </div>
        </MostStatCard>
      </div>
      <div className="col-6 p-4">
        {mostBuyingUsers && (
          <CSVLink
            className="btn btn-block bt btn-soft-primary btn-sm mb-1"
            filename="Les utilisateurs qui achètent le plus.csv"
            data={formatTopUsersToCSV(mostBuyingUsers)}
          >
            <i className="far fa-save mr-1" />
            Exporter en tant que CSV
          </CSVLink>
        )}
        <MostStatCard>
          <div className="py-2">
            <Collapsible title="Les utilisateurs qui achètent le plus" noLine>
              {mostBuyingUsers &&
                mostBuyingUsers.map(o => (
                  <StatCardElement
                    key={o._id}
                    image={
                      o.avatar
                        ? getImage(o.avatar)
                        : 'https://avatars.dicebear.com/v2/male/sam.svg?options[mood][]=happy'
                    }
                    subtitle={`Email ${o.email}`}
                    title={`${o.firstName} ${o.lastName}`}
                    iconOneFontAwsome="fa fa-money-bill-wave"
                    iconOneText={`A acheté ${o.buy} crédits`}
                    iconTwoFontAwsome="fa fa-coins"
                    iconTwoText={`A dépensé ${o.spend} crédits`}
                  />
                ))}
            </Collapsible>
          </div>
        </MostStatCard>
      </div>
      <div className="col-6 p-4">
        {mostSpendingUsers && (
          <CSVLink
            className="btn btn-block bt btn-soft-primary btn-sm mb-1"
            filename="Les utilisateurs qui consomment le plus de crédits.csv"
            data={formatTopUsersToCSV(mostSpendingUsers)}
          >
            <i className="far fa-save mr-1" />
            Exporter en tant que CSV
          </CSVLink>
        )}
        <MostStatCard>
          <div className="py-2">
            <Collapsible title="Les utilisateurs qui consomment le plus de crédits" noLine>
              {mostSpendingUsers &&
                mostSpendingUsers.map(o => (
                  <StatCardElement
                    key={o._id}
                    image={
                      o.avatar
                        ? getImage(o.avatar)
                        : 'https://avatars.dicebear.com/v2/male/sam.svg?options[mood][]=happy'
                    }
                    subtitle={`Email ${o.email}`}
                    title={`${o.firstName} ${o.lastName}`}
                    iconTwoFontAwsome="fa fa-money-bill-wave"
                    iconTwoText={`A acheté ${o.buy} crédits`}
                    iconOneFontAwsome="fa fa-coins"
                    iconOneText={`A dépensé ${o.spend} crédits`}
                  />
                ))}
            </Collapsible>
          </div>
        </MostStatCard>
      </div>
    </div>
  );
};

export default StaticRecords;
