import ExtraDataFields from 'containers/AnnouncementForm/ExtraDataFields';
import FinancialFields from 'containers/AnnouncementForm/FinancialFields';
import { INCENTIVES } from 'constants/incentives';
import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import SelectField from 'components/SelectField';
import Validators from 'helpers/formValidator';
import VariableFields from 'containers/AnnouncementForm/VariableFields';
import _ from 'lodash';
import { reduxForm } from 'redux-form';
import strings from 'constants/strings';
import structureTitleValue from 'helpers/structureTitleValue';
import useElBusiness from 'hooks/useElBusiness';
import { useSelector } from 'react-redux';

const FORM_NAME = 'editAnnouncementFormGeneralInformation';

const EditAnnouncementGeneralInformationForm = ({
  handleSubmit,
  initialValues,
  categories,
  activities,
  realEstateTypes,
  opportunities,
  dispatch,
  countries,
}) => {
  const {
    financialData,
    regions,
    handleActivityChange,
    handleOpportunityChange,
    selectedCategory,
    subActivities,
    subOpportunities,
  } = useElBusiness(dispatch, FORM_NAME, { categories, activities, countries, opportunities }, initialValues);

  const formState = useSelector(state => state.form);
  const country = formState?.[FORM_NAME]?.values?.country;

  return (
    <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center">
      <InputField
        label={strings.addAnnouncementTitleLabel}
        placeholder={strings.addAnnouncementTitlePlaceholder}
        name="title"
        validate={[Validators.required]}
      />
      <InputField
        label={strings.addAnnouncementDescriptionLabel}
        placeholder={strings.addAnnouncementDescriptionPlaceholder}
        name="description"
        type="textarea"
        validate={[Validators.required]}
      />
      <div className="row">
        <div className="col-md-6 mb-3">
          <SelectField
            options={regions}
            label={strings.addAnnouncementRegionLabel}
            placeholder={strings.addAnnouncementRegionPlaceholder}
            name="region"
            validate={[Validators.required]}
          />
        </div>
        <div className="col-md-6 mb-3">
          <InputField
            label={strings.addAnnouncementAddressLabel}
            placeholder={strings.addAnnouncementAddressPlaceholder}
            name="address"
            rightIcon={<span className="fa fa-map-marker" />}
            validate={[Validators.required]}
          />
        </div>
      </div>
      {country && financialData && (
        <FinancialFields financialData={financialData} formName={FORM_NAME} country={country} />
      )}
      <VariableFields
        incentives={INCENTIVES.map(o => ({ label: o, value: o }))}
        activities={structureTitleValue(activities)}
        handleActivityChange={handleActivityChange}
        handleOpportunityChange={handleOpportunityChange}
        opportunities={structureTitleValue(opportunities)}
        realEstateTypes={structureTitleValue(realEstateTypes)}
        selectedCategory={selectedCategory}
        subActivities={structureTitleValue(subActivities)}
        subOpportunities={structureTitleValue(subOpportunities)}
      />
      {selectedCategory && (
        <ExtraDataFields
          selectedCategory={selectedCategory.title}
          formName={FORM_NAME}
          isOpen={!_.isEmpty(initialValues?.extraData)}
        />
      )}

      <button type="submit" className="align-self-end btn btn-outline-secondary">
        {strings.userProfile.saveChanges}
      </button>
    </form>
  );
};

EditAnnouncementGeneralInformationForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  categories: PropTypes.array,
  dispatch: PropTypes.func,
  activities: PropTypes.array,
  realEstateTypes: PropTypes.array,
  opportunities: PropTypes.array,
  countries: PropTypes.array,
};

export default reduxForm({ form: FORM_NAME })(EditAnnouncementGeneralInformationForm);
