import PropTypes from 'prop-types';
import React from 'react';
import UserAvatar from 'components/UserAvatar';
import formatPrice from 'helpers/formatPrice';

const AnnouncementContact = ({ firstName, lastName, avatar, phoneNumber }) => {
  return (
    <div className="pl-lg-4">
      <div className="js-sticky-block card shadow-sm p-4">
        <div className="media align-items-center mb-4">
          <div className="u-avatar mr-3">
            <UserAvatar avatar={avatar} firstName={firstName} />
          </div>
          <div className="media-body">
            <h4 className="h6 mb-0 font-weight-bold ">
              {firstName}
              {` ${lastName}`}
            </h4>
          </div>
        </div>
        <p className="font-size-1 mb-2">
          <span className="fa fa-phone mr-1" />
          {formatPrice(phoneNumber)}
        </p>
      </div>
    </div>
  );
};

AnnouncementContact.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  avatar: PropTypes.string,
};

export default AnnouncementContact;
