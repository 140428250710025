/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { fetchAllUsers } from 'actions/Users';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FETCH_USERS_BY } from 'constants/types';
import CachedIcon from '@material-ui/icons/Cached';
import getColumns from 'pages/UserManagement/getColums';
import { useLocation } from 'react-router-dom';
import filterItems from './filterItems';
import DoubleCheckModal from './DoubleCheckModal';
import { fetchUsersAndSetColumns, setUpdateFunction } from './userManagementFuncitons';

const UserManagement = () => {
  const { users } = useSelector(({ usersReducer }) => usersReducer);
  const isLoading = useSelector(({ loading }) => loading.loading);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState(users);
  const dispatch = useDispatch();
  const location = useLocation();
  const handleAction = email => () => {
    setIsOpen(true);
    setSelectedUser(email);
  };
  const [columns, setColumns] = useState(getColumns(FETCH_USERS_BY.NOT_ADMIN, handleAction));

  useEffect(() => {
    fetchUsersAndSetColumns(location, dispatch, setColumns, handleAction);
  }, [location.pathname]);

  useEffect(() => {
    setFilteredData(filterItems(users, filter));
  }, [users, filter]);

  const onClose = () => {
    setIsOpen(false);
    setSelectedUser(undefined);
  };
  const handleSubmit = (value, transactionId) => {
    setUpdateFunction(location, dispatch, selectedUser, value, transactionId);
    onClose();
  };
  const DataTableHeader = React.useMemo(() => (
    <div className="d-flex">
      <span className="d-flex pr-4 justify-content-between align-items-center">Recherchez dans la liste</span>
      <div className="input-group">
        <input
          onChange={e => setFilter(e.target.value)}
          value={filter}
          type="text"
          name="filter"
          className="form-control"
        />
        <div onClick={() => setFilter('')} className="input-group-append">
          <span className="input-group-text  bg-primary text-white" id="basic-addon2">
            X
          </span>
        </div>
      </div>
      <button
        className="btn  btn-outline-primary btn-icon  ml-4"
        type="button"
        onClick={() => dispatch(fetchAllUsers())}
      >
        <CachedIcon />
      </button>
    </div>
  ));

  return (
    <>
      <DataTable
        title="User Management"
        noHeader
        subHeader
        subHeaderComponent={DataTableHeader}
        columns={columns}
        data={filteredData}
        progressPending={isLoading}
        persistTableHead
        fixedHeaderScrollHeight="calc(100vh - 300px)"
      />
      <DoubleCheckModal
        handleSubmit={handleSubmit}
        isCredits={location.pathname === '/add-credits'}
        onClose={onClose}
        isOpen={isOpen}
      />
    </>
  );
};

export default UserManagement;
