import PropTypes from 'prop-types';
import React from 'react';

const DetailsSubSection = ({ title, value }) => {
  return (
    <li className="d-flex justify-content-between py-1">
      <span className="font-weight-bold col-6 pl-0">{`${title}:`}</span>
      <span className="text-primary col-6 text-right pr-0">{value}</span>
    </li>
  );
};

DetailsSubSection.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
};

export default DetailsSubSection;
