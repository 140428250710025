/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Breadcrumbs = ({ category, subCategory, name, isInList }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb breadcrumb-no-gutter bg-transparent font-size-1">
        {!isInList && (
          <li className="breadcrumb-item">
            <Link to="/experts">Experts</Link>
          </li>
        )}
        {!isInList && (
          <li className="breadcrumb-item">
            <Link to={`/experts?category=${category?.slug.toLowerCase()}`}>{category?.title}</Link>
          </li>
        )}
        {subCategory?.title && (
          <li className="breadcrumb-item">
            <Link
              to={`/experts?category=${category?.slug.toLowerCase()}&sub-category=${subCategory?.slug.toLowerCase()}`}
            >
              {subCategory?.title}
            </Link>
          </li>
        )}
        {name && (
          <li className="breadcrumb-item active" aria-current="page">
            {name}
          </li>
        )}
      </ol>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  category: PropTypes.object,
  subCategory: PropTypes.object,
  name: PropTypes.string,
  isInList: PropTypes.bool,
};
export default Breadcrumbs;
