import React from 'react';
import PropTypes from 'prop-types';

const SocialButton = ({ href, isLight, faClass }) => {
  if (!href) return null;

  return (
    <a href={href} target="__blank" className="text-white">
      <button type="button" className={`btn btn-${isLight ? 'white text-dark' : 'dark text-white'} btn-icon ml-2`}>
        <i className={faClass} />
      </button>
    </a>
  );
};

SocialButton.propTypes = {
  href: PropTypes.string,
  faClass: PropTypes.string,
  isLight: PropTypes.bool,
};
export default SocialButton;
