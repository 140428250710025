import { fetchAllUsers, addUserCredits, grantPrevilege, banOrUnBanUser } from 'actions/Users';
import { FETCH_USERS_BY } from 'constants/types';
import getColumns from 'pages/UserManagement/getColums';

export const fetchUsersAndSetColumns = (location, dispatch, setColumns, handleAction) => {
  switch (location.pathname) {
    case '/add-credits':
      dispatch(fetchAllUsers(FETCH_USERS_BY.NOT_ADMIN));
      setColumns(getColumns(location.pathname, handleAction));
      break;
    case '/upgrade-role':
      dispatch(fetchAllUsers(FETCH_USERS_BY.NOT_ADMIN));
      setColumns(getColumns(location.pathname, handleAction));
      break;
    case '/downgrade-role':
      dispatch(fetchAllUsers(FETCH_USERS_BY.ADMINS));
      setColumns(getColumns(location.pathname, handleAction));
      break;
    case '/unban-user':
      dispatch(fetchAllUsers(FETCH_USERS_BY.BANNED));
      setColumns(getColumns(location.pathname, handleAction));
      break;
    case '/ban-user':
      dispatch(fetchAllUsers(FETCH_USERS_BY.UNBANNED));
      setColumns(getColumns(location.pathname, handleAction));
      break;
    default:
      dispatch(fetchAllUsers());
      setColumns(getColumns(location.pathname, handleAction));
      break;
  }
};

export const setUpdateFunction = (location, dispatch, selectedUser, value, transactionId) => {
  switch (location.pathname) {
    case '/add-credits':
      dispatch(addUserCredits(selectedUser, value, FETCH_USERS_BY.NOT_ADMIN, transactionId));
      break;
    case '/upgrade-role':
      dispatch(grantPrevilege(selectedUser, true, FETCH_USERS_BY.NOT_ADMIN));
      break;
    case '/downgrade-role':
      dispatch(grantPrevilege(selectedUser, false, FETCH_USERS_BY.ADMINS));
      break;
    case '/unban-user':
      dispatch(banOrUnBanUser(selectedUser, false, FETCH_USERS_BY.UNBANNED));
      break;
    case '/ban-user':
      dispatch(banOrUnBanUser(selectedUser, true, FETCH_USERS_BY.BANNED));
      break;
    default:
      dispatch(fetchAllUsers());
      break;
  }
};
