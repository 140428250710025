import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

export const PublicationDate = ({ createdAt }) => (
  <div className="mb-2 mb-md-0">
    <span className="font-size-1 font-weight-bold">Publiée le : </span>
    <span className="text-primary font-size-1">{moment(createdAt).format('LL')}</span>
  </div>
);
PublicationDate.propTypes = { createdAt: PropTypes.string };
