import PropTypes from 'prop-types';

export const labelIdTitle = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }),
).isRequired;

export const opportunitiesInterface = PropTypes.arrayOf(
  PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    types: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
).isRequired;
export const activitiesInterface = PropTypes.arrayOf(
  PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subActivities: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
).isRequired;
export const categoriesInterface = PropTypes.arrayOf(
  PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subCategories: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        financialData: PropTypes.shape({
          title: PropTypes.string,
          required: PropTypes.bool,
          annualIncrease: PropTypes.bool,
          units: PropTypes.arrayOf(PropTypes.string),
        }),
      }),
    ),
  }).isRequired,
).isRequired;

export const countriesInterface = PropTypes.arrayOf(
  PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    regions: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
).isRequired;

export const titleId = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
});
