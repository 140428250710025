import {
  activitiesInterface,
  categoriesInterface,
  countriesInterface,
  labelIdTitle,
  opportunitiesInterface,
} from 'interfaces';

import PropTypes from 'prop-types';

export default {
  countries: labelIdTitle,
  activities: labelIdTitle,
  realEstateTypes: labelIdTitle,
  opportunities: labelIdTitle,
  categories: labelIdTitle,
  unstructuredCategories: categoriesInterface,
  unstructuredActivities: activitiesInterface,
  unstructuredOpportunities: opportunitiesInterface,
  unstructuredCountries: countriesInterface,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
};

export const VariableFieldsPropTypes = {
  incentives: PropTypes.array,
  selectedCategory: PropTypes.object,
  activities: labelIdTitle,
  subActivities: labelIdTitle,
  handleActivityChange: PropTypes.func.isRequired,
  realEstateTypes: labelIdTitle,
  opportunities: labelIdTitle,
  handleOpportunityChange: PropTypes.func.isRequired,
  subOpportunities: labelIdTitle,
};
