import Fuse from 'fuse.js';

const filterItems = (items, value) => {
  if (value === '') return items;
  const options = {
    shouldSort: true,
    threshold: 0.2,
    location: 0,
    distance: 1000,
    minMatchCharLength: 1,
    keys: ['user', 'userEmail', 'admin', 'adminEmail', 'transactionId'],
  };
  const fuse = new Fuse(items, options);

  const res = fuse.search(value);

  return res.length > 0 ? res.map(el => el.item) : [];
};

export default filterItems;
