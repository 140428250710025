import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export const ExpertHeaderBreadCrumbs = ({ country, region, address }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb breadcrumb-no-gutter bg-transparent font-size-1">
        <li className="breadcrumb-item">
          <Link to={`/experts?country=${country?.title.toLowerCase()}`}>{country?.title}</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={`/experts?country=${country?.title.toLowerCase()}&region=${region?.title.toLowerCase()}`}>
            {region?.title}
          </Link>
        </li>
        {address && (
          <li className="breadcrumb-item">
            <span>{address}</span>
          </li>
        )}
      </ol>
    </nav>
  );
};

ExpertHeaderBreadCrumbs.propTypes = {
  country: PropTypes.object,
  region: PropTypes.object,
  address: PropTypes.string,
};
