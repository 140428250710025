import annonces from 'reducers/Annonces';
import auth from 'reducers/Auth';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import loading from 'reducers/Loading';
import usersReducer from 'reducers/Users';
import relatedData from 'reducers/relatedData';
import transactionsReducer from 'reducers/TransactionsReducer';
import expert from 'reducers/expert';
import expertList from 'reducers/expertList';

export default combineReducers({
  form,
  loading,
  relatedData,
  auth,
  usersReducer,
  annonces,
  transactionsReducer,
  expert,
  expertList,
});
