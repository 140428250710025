import React from 'react';
import calculateUserCredit from 'helpers/calculateUserCredit';
import moment from 'moment';

export default selectUser => [
  {
    name: 'Nom Complet',
    selector: 'firstName',
    cell: row => `${row.firstName} ${row.lastName}`,
    sortable: true,
  },
  {
    name: 'N° Téléphone',
    selector: 'phoneNumber',
    sortable: true,
  },
  {
    name: 'Adresse e-mail',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'Entreprise',
    selector: 'companyName',
    sortable: true,
  },
  {
    name: 'Pays',
    selector: 'country',
    sortable: true,
  },
  {
    name: 'Crédits',
    selector: row => calculateUserCredit(row.credits),
    sortable: true,
  },
  {
    name: 'Créé le',
    selector: 'createdAt',
    sortable: true,
    cell: row => moment(row.createdAt).format('LL'),
  },
  {
    center: true,
    name: 'Détails',
    cell: row => (
      <button className="btn btn-block btn-primary btn-icon" onClick={() => selectUser(row._id)} type="button">
        <i className="fa fa-info-circle text-white" />
      </button>
    ),
  },
];
