/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { fetchAnnouncementBySlug } from 'actions/Annonces';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import AnnouncementContainer from 'containers/Announcement';
import _ from 'lodash';

const Annonce = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedAnnonce = useSelector(({ annonces }) => annonces.selectedAnnouncement);

  const { slug } = match.params;

  useEffect(() => {
    fetchBySlug(slug);
  }, [slug]);
  const fetchBySlug = async _slug => {
    const response = await dispatch(fetchAnnouncementBySlug(_slug));

    if (response === 404) history.push('/announcement-not-found');
  };

  if (typeof selectedAnnonce !== 'object' || _.isEmpty(selectedAnnonce)) return <div />;

  return (
    <div className="container py-4" style={{ maxWidth: '1000px' }}>
      <AnnouncementContainer
        isLogged
        relatedAnnouncements={[]}
        selectedAnnouncement={selectedAnnonce}
        history={history}
      />
    </div>
  );
};

export default Annonce;
