import Badge from '@material-ui/core/Badge';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';

const SidebarLink = ({ path, name, Icon, badge, notifications }) => {
  let badgeNum = notifications;

  if (notifications && notifications > 99) badgeNum = 99;

  return (
    <Link className="text-black-50" to={path}>
      <ListItem button>
        <ListItemIcon>
          {badge && notifications > 0 ? (
            <Badge color="secondary" badgeContent={badgeNum}>
              <Icon />
            </Badge>
          ) : (
            <Icon />
          )}
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    </Link>
  );
};

SidebarLink.propTypes = {
  path: PropTypes.string,
  name: PropTypes.string,
  Icon: PropTypes.object,
  badge: PropTypes.bool,
  notifications: PropTypes.number,
};

export default SidebarLink;
