import api from 'api';
import buildFormData from 'helpers/buildFormData';

export const incrementAnnouncementViews = async announcementId => {
  await api.put(`/announcements/${announcementId}/increment-views`);
};

export const editGeneralData = async (announcementId, updateData) => {
  const response = await api.put(`/announcements/${announcementId}`, updateData);

  return response.data;
};
export const editPhotosOrder = async (announcementId, updateData) => {
  const response = await api.put(`/announcements/${announcementId}/update-picture-order`, updateData);

  return response.data;
};

export const boostAnnouncement = async (announcementId, updateData) => {
  const response = await api.put(`/announcements/${announcementId}/boost-announcement`, updateData);

  return response.data;
};

export const validateAnnoncement = async announcementId => {
  const response = await api.get(`/announcements/validate/${announcementId}`);

  return response.data;
};
export const deleteAnnoncement = async announcementId => {
  const response = await api.delete(`/announcements/${announcementId}/admin`);

  return response.data;
};

export const addPictures = async (announcementId, updateData) => {
  const RequestBody = new FormData();

  buildFormData(RequestBody, updateData);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: api.defaults.headers.Authorization,
    },
  };
  const response = await api.put(`/announcements/${announcementId}/add-new-pictures`, RequestBody, config);

  return response.data;
};
