/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import categoryNames from 'constants/categoryNames';
import { change } from 'redux-form';
import strings from 'constants/strings';
import { useDispatch } from 'react-redux';

const ExtraFieldsHeader = ({ title, intervals }) => {
  const colSize = Math.floor(12 / (intervals.length + 1));

  return (
    <div className="flex-row d-flex align-items-center my-2">
      <span className={`col-md-${colSize} text-center font-weight-bold `}>{title}</span>
      {intervals.map(el => (
        <span className={`col-md-${colSize} text-center font-weight-bold`}>{el}</span>
      ))}
    </div>
  );
};

const ExtraFieldsContent = ({ title, intervals, inputName }) => {
  const colSize = Math.floor(12 / (intervals.length + 1));

  return (
    <div className="flex-row d-flex align-items-center">
      <span className={`col-md-${colSize}`}>{title}</span>
      {intervals.map(el => (
        <div className={`col-md-${colSize}`}>
          <InputField name={`extraData[${el}].${inputName}`} />
        </div>
      ))}
    </div>
  );
};

const ExtraDataFieldsSection = ({ selectedCategory }) => {
  let intervalsArray;

  switch (selectedCategory) {
    case categoryNames.CATEGORY_ENTREPRISE:
      intervalsArray = ['N-2', 'N-1', 'N'];

      return (
        <>
          <ExtraFieldsHeader title="Rubriques" intervals={intervalsArray} />
          <ExtraFieldsContent
            title={strings.extraDataFieldsTitles.chiffresAffaires}
            intervals={intervalsArray}
            inputName="chiffresAffaires"
          />
          <ExtraFieldsContent
            title="Charges d’exploitations"
            intervals={intervalsArray}
            inputName="chargesExploitation"
          />
          <ExtraFieldsContent
            title={strings.extraDataFieldsTitles.chargesPersonnel}
            intervals={intervalsArray}
            inputName="chargesPersonnel"
          />
          <ExtraFieldsContent
            title={strings.extraDataFieldsTitles.resultatNet}
            intervals={intervalsArray}
            inputName="resultatNet"
          />
          <ExtraFieldsContent
            title={strings.extraDataFieldsTitles.nombrePersonnel}
            intervals={intervalsArray}
            inputName="nombrePersonnel"
          />
        </>
      );

    case categoryNames.CATEGORY_IMMOBILIER:
      intervalsArray = ['N-1', 'N'];

      return (
        <>
          <ExtraFieldsHeader title="Rubriques" intervals={intervalsArray} />
          <ExtraFieldsContent
            title={strings.extraDataFieldsTitles.revenus}
            intervals={intervalsArray}
            inputName="revenus"
          />
          <ExtraFieldsContent
            title={strings.extraDataFieldsTitles.charges}
            intervals={intervalsArray}
            inputName="charges"
          />
        </>
      );

    case categoryNames.CATEGORY_INVESTISSEMENT:
      intervalsArray = ['N-2', 'N-1', 'N', 'N+1', 'N+2'];

      return (
        <>
          <ExtraFieldsHeader title="Rubriques" intervals={intervalsArray} />
          <ExtraFieldsContent
            title={strings.extraDataFieldsTitles.capitalSocial}
            intervals={intervalsArray}
            inputName="capitalSocial"
          />
          <ExtraFieldsContent
            title={strings.extraDataFieldsTitles.totalBilan}
            intervals={intervalsArray}
            inputName="totalBilan"
          />
          <ExtraFieldsContent
            title={strings.extraDataFieldsTitles.chiffresAffaires}
            intervals={intervalsArray}
            inputName="chiffresAffaires"
          />
          <ExtraFieldsContent
            title={strings.extraDataFieldsTitles.chargesExploitation}
            intervals={intervalsArray}
            inputName="chargesExploitation"
          />
          <ExtraFieldsContent
            title={strings.extraDataFieldsTitles.chargesPersonnel}
            intervals={intervalsArray}
            inputName="chargesPersonnel"
          />
          <ExtraFieldsContent
            title={strings.extraDataFieldsTitles.resultatNet}
            intervals={intervalsArray}
            inputName="resultatNet"
          />
          <ExtraFieldsContent
            title={strings.extraDataFieldsTitles.nombrePersonnel}
            intervals={intervalsArray}
            inputName="nombrePersonnel"
          />
        </>
      );
    default:
      return null;
  }
};

const ExtraDataFields = ({ selectedCategory, formName, isOpen = false }) => {
  const [extraFieldsShown, setExtraFieldsShown] = useState(isOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    if (extraFieldsShown) return;
    dispatch(change(formName, 'extraInfo', ''));
    dispatch(change(formName, 'extraData', {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraFieldsShown]);

  return (
    <div className="d-flex flex-column">
      {extraFieldsShown && (
        <div className="p-2 card shadow">
          <ExtraDataFieldsSection selectedCategory={selectedCategory} />
          <div className="mx-3 mt-2">
            <InputField
              name="extraInfo"
              label={strings.extraDataFieldsTitles.extraInfo}
              type="textarea"
              placeholder={strings.extraDataFieldsTitles.extraInfo}
            />
          </div>
        </div>
      )}
      <button
        type="button"
        className="btn btn-primary align-self-center my-4"
        onClick={() => setExtraFieldsShown(!extraFieldsShown)}
      >
        {extraFieldsShown ? strings.addAnnouncementRemoveExtraFieldSection : strings.addAnnouncementAddExtraField}
      </button>
    </div>
  );
};

ExtraDataFields.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};
ExtraDataFieldsSection.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
};

ExtraFieldsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  intervals: PropTypes.array.isRequired,
};

ExtraFieldsContent.propTypes = {
  title: PropTypes.string.isRequired,
  intervals: PropTypes.array.isRequired,
  inputName: PropTypes.string.isRequired,
};

export default ExtraDataFields;
