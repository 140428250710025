import React from 'react';
import PropTypes from 'prop-types';
import TeamMember from './TeamMember';
import { useExpertTeam } from './useExpertTeam';

const ExpertTeam = ({ teamRef }) => {
  const team = useExpertTeam();

  return (
    <div className="space-bottom-3" ref={teamRef}>
      <h2 className="pt-7 font-weight-semi-bold">Équipe :</h2>
      {team.map((member, index) => (
        <TeamMember isLeft={index % 2} key={member._id} {...member} />
      ))}
    </div>
  );
};

ExpertTeam.propTypes = { teamRef: PropTypes.object };

export default ExpertTeam;
