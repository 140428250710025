import SEO from 'components/SEO';
import FacebookTypes from 'constants/facebookTypes';
import Breadcrumbs from 'containers/ExpertContainer/Breadcrumbs';
import { useBreadcrumbs } from 'containers/ExpertContainer/Breadcrumbs/useBreadcrumbs';
import ExpertHeader from 'containers/ExpertContainer/ExpertHeader';
import ExpertTeam from 'containers/ExpertContainer/ExpertTeam';
import getImage from 'helpers/getImage';
import React from 'react';
import { useExpertPage } from './useExpertPage';

const ExpertPage = () => {
  const { isLoading, navigatorRef, scrollToTeam, seoData, handleAcceptExpert } = useExpertPage();
  const { category, subCategory, name } = useBreadcrumbs();

  return (
    <div className="container">
      <SEO
        title={`${seoData.title} | elbusines.com | les meilleurs experts en Tunisie`}
        description={seoData.description}
        image={getImage(seoData.logo?.[0])}
        type={FacebookTypes.product.product}
      />
      <Breadcrumbs {...{ category, subCategory, name }} />
      {!isLoading && <ExpertHeader onClickScroll={scrollToTeam} />}
      {!isLoading && <ExpertTeam teamRef={navigatorRef} />}
      <div className="space-bottom-2">
        <button onClick={handleAcceptExpert} type="button" className="btn btn-block btn-danger ">
          Accepter l&apos;expert
        </button>
      </div>
    </div>
  );
};

export default ExpertPage;
