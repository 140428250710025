import ImagePickerField from 'components/ImagePickerField';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import strings from 'constants/strings';

const EditAnnouncementAddPicture = ({ photos, handleSubmit }) => {
  if (photos.length === 10) return <span className="font-weight-bold">{strings.userProfile.maxFilesReached}</span>;

  return (
    <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center">
      <ImagePickerField name="photos" label={strings.imageUploadDefaultMessage} maxFiles={10 - photos.length} />
      <button type="submit" className="align-self-end btn btn-outline-secondary mt-2">
        {strings.userProfile.saveChanges}
      </button>
    </form>
  );
};

EditAnnouncementAddPicture.propTypes = {
  photos: PropTypes.array,
  handleSubmit: PropTypes.func,
};

export default reduxForm({ form: 'editAnnouncementFormAddPicture' })(EditAnnouncementAddPicture);
