import PropTypes from 'prop-types';
import React from 'react';
import formatPrice from 'helpers/formatPrice';
import moment from 'moment';
import strings from 'constants/strings';

const AnnouncementHeader = ({ title, financial, createdAt }) => {
  // eslint-disable-next-line no-restricted-globals
  const hasPrice = isNaN(financial?.value);

  return (
    <div className="col-lg-8">
      <div className="row justify-content-lg-between">
        <div className="col-lg-8">
          <h1 className="h3 font-weight-bold">{title}</h1>
        </div>
        {financial?.value && (
          <div className="col-lg-4 align-self-lg-end text-lg-right mb-5 mb-lg-0">
            <span className="h3 text-primary font-weight-medium">
              {hasPrice ? '' : formatPrice(financial.value, financial.unit)}
            </span>
          </div>
        )}
      </div>
      <div className="d-md-flex justify-content-md-start align-items-md-center text-center mt-3 ">
        <div className="mr-md-auto mb-2 mb-md-0">
          <span className="font-size-1 font-weight-bold">{`${strings.announcementCard.postedOn}: `}</span>
          <span className="text-primary font-size-1">{moment(createdAt).format('LL')}</span>
        </div>
      </div>
    </div>
  );
};

AnnouncementHeader.propTypes = {
  title: PropTypes.string.isRequired,
  financial: PropTypes.shape({
    value: PropTypes.number,
    unit: PropTypes.string.isRequired,
  }),
  createdAt: PropTypes.string.isRequired,
};

export default AnnouncementHeader;
