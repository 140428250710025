import { deleteAnnounce, validateAnnounce } from 'actions/Annonces/update';
import { useDispatch, useSelector } from 'react-redux';
import AnnouncementCard from 'components/AnnoucementCard';
import React from 'react';
import strings from 'constants/strings';
import { useHistory } from 'react-router-dom';

const AnnoncesList = () => {
  const disptach = useDispatch();
  const annoncesList = useSelector(({ annonces }) => annonces.announcements);
  const history = useHistory();

  return (
    <>
      <div className="container">
        {annoncesList && annoncesList.length > 0 ? 
          annoncesList.map(announcement => (
            <AnnouncementCard
              announcement={announcement}
              key={announcement._id}
              onEdit={() => history.push(`/modifier-annonce/${announcement.slug}`)}
              onValidate={() => disptach(validateAnnounce(announcement))}
              onDelete={() => disptach(deleteAnnounce(announcement))}
            />
          ))
         : (
           <span className="font-weight-bold">{strings.userProfile.noAnnouncements}</span>
        )}
      </div>
    </>
  );
};

export default AnnoncesList;
