import moment from 'moment';
import formatToCsv from './formatToCsv';

const formatAnnouncementsToCSV = announcements =>
  formatToCsv(announcements, [
    {
      label: 'Titre',
      value: 'title',
    },
    {
      label: 'Réference',
      value: 'announcementRef',
    },
    {
      label: 'Vues',
      value: 'views',
    },
    {
      label: 'Dévoilée',
      value: 'numberOfUnlocks',
    },
    {
      label: 'Catégorie',
      value: 'category.title',
    },
    {
      label: 'Sous catégorie',
      value: 'subCategory.title',
    },
    {
      label: 'Région',
      value: 'country.title',
    },
    {
      label: "Date d'ajout",
      value: 'createdAt',
      transform: createdAt => moment(createdAt).format('LL'),
    },
    {
      label: 'Ajoutée par',
      value: 'addedBy.email',
    },
    {
      label: 'Sponsorisation',
      value: 'premium.premiumType',
      transform: premiumType => {
        switch (premiumType) {
          case 'tier1':
            return 'Premium';

          case 'tier2':
            return 'Starter';
          default:
            return 'Free';
        }
      },
    },
    {
      label: 'Prix',
      value: 'financial',
      transform: financial => {
        if (!financial) return '';

        return `${financial.value} ${financial.unit}`;
      },
    },
  ]);

export default formatAnnouncementsToCSV;
