import React from 'react';
import ExpertCard from './ExpertCard';
import { useExpertListContainer } from './useExpertListContainer';

const ExpertListContainer = () => {
  const expertList = useExpertListContainer();

  return (
    <div className="row row-cols-1 row-cols-sm-2 space-1">
      {expertList?.map(expert => <ExpertCard key={expert._id} {...expert} />) || null}
    </div>
  );
};

export default ExpertListContainer;
