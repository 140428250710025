/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { INCENTIVES } from 'constants/incentives';
import _ from 'lodash';
import resetFields from 'helpers/resetFields';
import structureTitleValue from 'helpers/structureTitleValue';

const useElBusiness = (dispatch, formName, { categories, countries, activities, opportunities }, initialValues) => {
  const [extraFields, setExtraFields] = useState([]);
  const [subCategories, setsubCategories] = useState([]);
  const [subActivities, setSubActivities] = useState([]);
  const [subOpportunities, setSubOpportunities] = useState([]);
  const [selectedCategory, setselectedCategory] = useState();
  const [incentives, setIncentives] = useState([]);
  const [regions, setRegions] = useState([]);
  const [financialData, setFinancialData] = useState();

  useEffect(() => {
    if (!_.isEmpty(initialValues)) initializeData();
  }, [initialValues]);
  const initializeData = () => {
    const {
      category: { slug: categorySlug },
      subCategory: { slug: subCategorySlug },
      activity,
      opportunity,
      options,
      country: { id: countryId },
    } = initialValues;
    const extractedCategory = categories.find(category => category.slug === categorySlug);
    const extractedFinancial = extractedCategory.subCategories.find(
      subCategory => subCategory.slug === subCategorySlug,
    ).financialData;

    setRegions(structureTitleValue(countries.find(element => element._id === countryId).regions));
    if (activity) setSubActivities(activities.find(element => element._id === activity.id).subActivities);
    if (opportunity) setSubOpportunities(opportunities.find(element => element._id === opportunity.id).types);
    const iterator = Array(options.length).fill(null);

    setExtraFields(iterator);
    setselectedCategory(extractedCategory);
    setFinancialData(extractedFinancial);
  };

  const handleAddExtraFields = () => {
    setExtraFields([...extraFields, null]);
  };
  const handleRemoveExtraField = index => {
    const extraFieldsCopy = [...extraFields];

    resetFields(formName, [`options[${index}]`], dispatch);
    extraFieldsCopy.splice(index, 1);
    setExtraFields(extraFieldsCopy);
  };

  const handleCategoryChange = category => {
    const extracted = handleChange(category, categories, 'subCategory', setsubCategories, 'subCategories');

    setFinancialData(undefined);
    setselectedCategory(extracted);
  };

  const handleCountryChange = country => {
    handleChange(country, countries, 'region', setRegions, 'regions');
  };

  const handleSubCategoryChange = subCategory => {
    handleChange(subCategory, selectedCategory.subCategories, undefined, setFinancialData, 'financialData', false);
  };

  const handleActivityChange = newActivity => {
    handleChange(newActivity, activities, 'subActivity', setSubActivities, 'subActivities');
  };

  const handleOpportunityChange = newOpportunity => {
    handleChange(newOpportunity, opportunities, 'subOpportunity', setSubOpportunities, 'types');

    setIncentives(INCENTIVES.map(o => ({ label: o, value: o })));
  };

  const handleChange = (newOption, rawData, fieldToUnregister, setState, fieldToExtract, structureData = true) => {
    const {
      value: { id },
    } = newOption;
    const extracted = rawData.find(element => element._id === id);

    resetFields('announcementForm', [fieldToUnregister], dispatch);
    setState(structureData ? structureTitleValue(extracted[fieldToExtract]) : extracted[fieldToExtract]);

    return extracted;
  };

  return {
    extraFields,
    subCategories,
    subActivities,
    selectedCategory,
    subOpportunities,
    incentives,
    regions,
    financialData,
    handleAddExtraFields,
    handleRemoveExtraField,
    handleCategoryChange,
    handleActivityChange,
    handleOpportunityChange,
    handleCountryChange,
    handleSubCategoryChange,
  };
};

export default useElBusiness;
