import PropTypes from 'prop-types';
import React from 'react';
import strings from 'constants/strings';

const AnnouncementDescription = ({ description }) => {
  return (
    <>
      <div className="mb-3 mt-6">
        <h3 className="h5 font-weight-bold">{`${strings.description}:`}</h3>
      </div>
      <p className="text-justify">{description}</p>
    </>
  );
};

AnnouncementDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default AnnouncementDescription;
