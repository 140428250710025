/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useTransactions from 'hooks/useTransactions';

import Collapsible from 'components/Collapsible';
import Chart from 'components/Chart';
import { formatDataForChart } from 'helpers/formatDataForChart';
import { CSVLink } from 'react-csv';
import formatChartToCSV from 'helpers/formatChartToCSV';
import TransactionsDataTable from './TransactionsDataTable';
import StaticRecords from './StaticRecords';

const Home = () => {
  const { dataTableTransaction, spendingCurve, buyingCurve } = useTransactions();

  return (
    <div className="container">
      <StaticRecords />
      <Collapsible title="Courbe de dépenses des utilisateurs (en crédits)">
        {spendingCurve?.length > 0 && (
          <div className="d-flex flex-column" style={{ height: '50vh' }}>
            <CSVLink
              className="btn  align-self-end bt btn-soft-primary btn-sm mb-1"
              filename="Dépenses des utilisateurs.csv"
              data={formatChartToCSV(spendingCurve)}
            >
              <i className="far fa-save mr-1" />
              Exporter en tant que CSV
            </CSVLink>
            <Chart data={formatDataForChart(spendingCurve)} />
          </div>
        )}
      </Collapsible>
      <Collapsible title="Courbe d'achats de crédits (en crédits)">
        {buyingCurve?.length > 0 && (
          <div className="d-flex flex-column" style={{ height: '50vh' }}>
            <CSVLink
              className="btn  align-self-end bt btn-soft-primary btn-sm mb-1"
              filename="Achats de crédits.csv"
              data={formatChartToCSV(buyingCurve)}
            >
              <i className="far fa-save mr-1" />
              Exporter en tant que CSV
            </CSVLink>
            <Chart data={formatDataForChart(buyingCurve)} />
          </div>
        )}
      </Collapsible>
      <Collapsible title="Liste des transactions">
        <TransactionsDataTable data={dataTableTransaction} />
      </Collapsible>
    </div>
  );
};

export default Home;
