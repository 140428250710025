import moment from 'moment';
import formatToCsv from './formatToCsv';

const formatChartToCSV = chartData =>
  formatToCsv(chartData, [
    {
      label: 'Date',
      value: 'date',
      transform: date => moment(date).format('LL'),
    },
    {
      label: 'Crédits',
      value: 'value',
    },
    {
      label: 'N° de transactions',
      value: 'numberOfTransactions',
    },
  ]);

export default formatChartToCSV;
