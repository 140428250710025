import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';

import PropTypes from 'prop-types';

const Collapsible = ({ open = false, children, title, noLine = false }) => {
  const [isOpen, setIsOpen] = useState(open);
  const handleFilterOpening = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="mb-5 position-relative">
        <h2 className="h5 font-weight-bold ">{title}</h2>
        <IconButton className="position-absolute top-0 right-0 pt-2 pl-3" onClick={handleFilterOpening}>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </div>
      <Collapse isOpened={isOpen}>{children}</Collapse>

      {!noLine && <hr className="my-6" />}
    </>
  );
};

Collapsible.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  noLine: PropTypes.bool,
};

export default Collapsible;
