/* eslint-disable max-lines */
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import formatPrice from 'helpers/formatPrice';
import getImage from 'helpers/getImage';
import moment from 'moment';
import smallDescription from 'helpers/smallDescription';
import strings from 'constants/strings';

const AnnouncementCard = ({ announcement, onEdit, onValidate, onDelete }) => {
  const {
    title,
    financial,
    photos,
    slug,
    category,
    description,
    country,
    createdAt,
    region,
    isValid,
    premium,
    isSold,
  } = announcement;

  const onEditPress = () => onEdit(announcement);
  const onValidatePress = () => onValidate(announcement);
  const onDeletePress = () => onDelete(announcement);
  const premiumBadgeText = useMemo(() => {
    if (!premium.isPremium) return 'Free';
    switch (premium.premiumType) {
      case 'tier1':
        return 'Premium';
      case 'tier2':
        return 'Starter';
      default:
        return '';
    }
  }, [premium.isPremium, premium.premiumType]);

  return (
    <div className="border-bottom pb-2 mb-5">
      <div className="row">
        <div className="col-md-4 d-md-flex align-items-md-start flex-md-column mb-5 mb-md-0">
          <div className="dark-overlay w-100 rounded overflow-hidden" style={{ maxHeight: 200 }}>
            <div
              className="image-preview"
              style={{
                backgroundImage: `url("${encodeURI(getImage(photos[0]))}")`,
              }}
            />
          </div>
          <div className="position-absolute top-0 left-1 pt-2 pl-2">
            <span className="badge badge-primary p-1 mr-1 ">{category.title}</span>
            {premium.isPremium && <span className="badge badge-danger p-1 mr-1 ">Annonce sponsorisée</span>}
            <span className="badge  badge-warning p-1 mr-1">{premiumBadgeText}</span>
            {isSold && <span className="badge  badge-success p-1 mr-1">Vendue</span>}
          </div>
          {!isValid && (
            <div className="position-absolute bottom-0 left-1 pb-5 pl-2">
              <span className="badge badge-danger p-1 ">Annonce en cours de validation</span>
            </div>
          )}

          <div className="media align-items-center mt-auto">
            <div className="media-body mt-2">
              <small className="d-block text-muted">
                {moment(createdAt).format(`[${strings.announcementCard.postedOn}] LL`)}
              </small>
              {isValid && premium.isPremium && (
                <>
                  <small className="d-block text-muted">
                    {moment(premium.startDate).format(`[Début sponsorisation] LL`)}
                  </small>
                  {premium.expiryDate && (
                    <small className="d-block text-muted">
                      {moment(premium.expiryDate).format(`[Fin sponsorisation] LL`)}
                    </small>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <span className="font-weight-bold">{title}</span>
          {financial?.value && (
            <div className="row">
              <div className="col-8">
                <h2 className="h4 mb-1">{formatPrice(financial.value, financial.unit)}</h2>
              </div>
            </div>
          )}
          <div className="mb-3 text-primary font-size-1 ">
            <span className="fa fa-map-marker mr-1" />
            {`${country.title}, ${region.title}`}
          </div>
          <p className="font-size-1">{smallDescription(description)}</p>
          <div className="d-flex justify-content-end font-size-1">
            <Link className="btn btn-sm btn-soft-primary transition-3d-hover mx-3" to={`/annonce/${slug}`}>
              {strings.announcementCard.details}
              <span className="fa fa-angle-right ml-1" />
            </Link>
            <button
              className="btn btn-sm btn-soft-primary transition-3d-hover mx-3"
              type="button"
              onClick={onEditPress}
            >
              {strings.announcementCard.edit}
              <span className="fa fa-angle-right ml-1" />
            </button>
            {!isValid && (
              <button
                className="btn btn-sm btn-soft-success transition-3d-hover mx-3"
                type="button"
                onClick={onValidatePress}
              >
                Valider l&apos;annonce
                <span className="fa fa-angle-right ml-1" />
              </button>
            )}
            <button
              className="btn btn-sm btn-soft-danger transition-3d-hover mx-3"
              type="button"
              onClick={onDeletePress}
            >
              Supprimer l&apos;annonce
              <span className="fa fa-angle-right ml-1" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AnnouncementCard.propTypes = {
  announcement: PropTypes.object,
  onEdit: PropTypes.func,
  onValidate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AnnouncementCard;
