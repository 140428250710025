import { GET_EXPERTS_LIST, GET_ONE_EXPERT, GET_PENDING_EXPERTS_LIST } from 'constants/types/expert';
import { startLoading, stopLoading } from 'actions/Loading';
import { toast } from 'react-toastify';
import { clientApiV2, resetAuthToken } from 'api';

export const fetchExpertBySlug = slug => async (dispatch, getState) => {
  const state = getState();

  resetAuthToken(state.auth?.token?.accessToken);
  dispatch(startLoading());
  try {
    const expert = await clientApiV2.get(`/experts/admin/${slug}`);

    dispatch({ type: GET_ONE_EXPERT, payload: expert.data });
  } catch (error) {
    toast(error.response.data, { type: 'error' });
  }
  dispatch(stopLoading());
};

export const acceptExpertBySlug = slug => async (dispatch, getState) => {
  const state = getState();

  resetAuthToken(state.auth?.token?.accessToken);
  dispatch(startLoading());
  try {
    const expert = await clientApiV2.put(`/experts/admin/${slug}`);

    dispatch({ type: GET_ONE_EXPERT, payload: expert.data });
  } catch (error) {
    toast(error.response.data, { type: 'error' });
  }
  dispatch(stopLoading());
};

export const fetchPendingExpertsList = () => async dispatch => {
  dispatch(startLoading());
  try {
    const experts = await clientApiV2.get(`/experts/pending`);

    dispatch({
      type: GET_PENDING_EXPERTS_LIST,
      payload: experts.data.totalItems
        ? experts.data
        : {
            page: 1,
            totalItems: 0,
            hasNextPage: false,
            totalPages: 0,
            items: [],
          },
    });
  } catch (error) {
    toast(error.response.data, { type: 'error' });
  }
  dispatch(stopLoading());
};

export const fetchExpertsList = () => async dispatch => {
  dispatch(startLoading());
  try {
    const experts = await clientApiV2.get(`/experts/admin`);

    dispatch({
      type: GET_EXPERTS_LIST,
      payload: experts.data.totalItems
        ? experts.data
        : {
            page: 1,
            totalItems: 0,
            hasNextPage: false,
            totalPages: 0,
            items: [],
          },
    });
  } catch (error) {
    toast(error.response.data, { type: 'error' });
  }
  dispatch(stopLoading());
};
