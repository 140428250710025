import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import getImage from 'helpers/getImage';
import strings from 'constants/strings';

const SortableItem = SortableElement(({ value, handleDelete, showDeleteButton }) => (
  <div className="sortable-image m-1 shadow-lg" style={{ backgroundImage: `url(${encodeURI(getImage(value))})`, position: 'relative' }}>
    {showDeleteButton && (
      <div className="position-absolute top-0 right-0 mr-2 mt-2">
        <button
          className="fa fa-times  pointer-event bg-transparent border-0"
          onClick={handleDelete}
          type="button"
          label="close-option"
          tabIndex={0}
        />
      </div>
    )}
  </div>
));

const SortableList = SortableContainer(({ items, handleDelete, showDeleteButton }) => {
  return (
    <div className="row justify-content-center">
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value}`}
          index={index}
          value={value}
          handleDelete={() => handleDelete(index)}
          showDeleteButton={showDeleteButton}
        />
      ))}
    </div>
  );
});

const EditAnnouncementRearrangePhotos = ({ photos, onSubmit }) => {
  const [finalArray, setFinalArray] = useState(photos);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setFinalArray(arrayMove(finalArray, oldIndex, newIndex));
  };

  const handleDelete = index => {
    const finalArrayCopy = [...finalArray];

    finalArrayCopy.splice(index, 1);
    setFinalArray(finalArrayCopy);
  };

  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="text-center mb-3">
        <p>{strings.userProfile.reorderPhotosText}</p>
      </div>
      <SortableList
        items={finalArray}
        onSortEnd={onSortEnd}
        axis="xy"
        handleDelete={handleDelete}
        showDeleteButton={finalArray.length > 1}
      />
      {photos.length > 1 && (
        <button type="button" className="align-self-end btn btn-outline-secondary mt-2" onClick={() => onSubmit(finalArray)}>
          {strings.userProfile.saveChanges}
        </button>
      )}
    </div>
  );
};

EditAnnouncementRearrangePhotos.propTypes = {
  photos: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default EditAnnouncementRearrangePhotos;
