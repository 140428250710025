import React from 'react';
import PropTypes from 'prop-types';
import getImage from 'helpers/getImage';
import './index.scss';
import SocialButton from './SocialButton';

const TeamMember = ({ image, name, bio, facebook, twitter, linkedin, cv, email, phone, isLeft }) => {
  return (
    <div className={`${isLeft ? 'bg-soft-primary' : 'bg-dark text-white'} rounded my-7 container space-1 space-md-2`}>
      <div className={`card bg-transparent shadow-none  ${isLeft ? 'border-dark' : ''}`}>
        <div className={`row ${isLeft ? '' : 'flex-row-reverse'}`}>
          <div className="col-lg-4 py-5">
            <img
              style={{
                maxHeight: '350px',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              className="img-fluid fill-available rounded shadow-lg mx-3"
              src={getImage(image[0])}
              alt={name}
            />
          </div>
          <div className="col-lg-8 py-5">
            <div className="card-body h-100 rounded p-0 p-md-4">
              <h3 className="mb-3 mb-lg-0">{name}</h3>
              <div className="mb-3 mb-lg-0 pr-lg-5">
                <p className="font-weight-normal mb-4">{bio}</p>
              </div>
              {(phone || email) && (
                <div className="d-flex justify-content-between">
                  {phone && (
                    <div>
                      <strong className="pr-4">Téléphone:</strong>
                      <a href={`tel:${phone}`}>{phone}</a>
                    </div>
                  )}
                  {email && (
                    <div>
                      <strong className="pr-4">Email:</strong>
                      <a href={`mailto:${email}`}>{email}</a>
                    </div>
                  )}
                </div>
              )}
              <div className="d-flex justify-content-between">
                <a target="__blank" href={getImage(cv)}>
                  <button type="button" className="btn mt-4 btn-sm btn-soft-primary">
                    {` Télécharger le cv de ${name} `}
                    <i className="fas fa-cloud-download-alt" />
                  </button>
                </a>
                {(twitter || linkedin || facebook) && (
                  <div className="d-flex pt-4 justify-content-end">
                    <SocialButton href={twitter} isLight={!isLeft} faClass="fab fa-twitter-square" />
                    <SocialButton href={linkedin} isLight={!isLeft} faClass="fab fa-linkedin" />
                    <SocialButton href={facebook} isLight={!isLeft} faClass="fab fa-facebook-square" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TeamMember.propTypes = {
  image: PropTypes.array,
  name: PropTypes.string,
  isLeft: PropTypes.bool,
  bio: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  linkedin: PropTypes.string,
  cv: PropTypes.array,
  email: PropTypes.string,
  phone: PropTypes.string,
};

export default TeamMember;
