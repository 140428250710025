import {
  FETCH_ANNOUNCEMENT_BY_SLUG,
  FETCH_UNVERIVIED_ANNONCES,
  SELECT_ANNOUNCEMENT,
  SET_RELATED_DATA,
  FETCH_ALL_ANNOUNCEMENTS_SUCCESS,
  FETCH_ALL_ANNOUNCEMENTS_FAILURE,
} from 'constants/types';
import { startLoading, stopLoading } from 'actions/Loading';
import api from 'api';
import { errorHandler } from 'helpers/errorHandler';

export const fetchUnvalidAnnounces = () => async dispatch => {
  dispatch(startLoading());
  try {
    const annonces = await api.get('/announcements/unverified');

    dispatch({ type: FETCH_UNVERIVIED_ANNONCES, payload: annonces.data });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const selectAnnouncement = announcement => dispatch => {
  dispatch({ type: SELECT_ANNOUNCEMENT, payload: announcement });
};

export const fetchAnnouncementBySlug = slug => async dispatch => {
  dispatch(startLoading());

  try {
    if (!slug) throw Error('No slug');
    const { data } = await api.get(`/announcements/slug/${slug}`);

    dispatch({
      type: FETCH_ANNOUNCEMENT_BY_SLUG,
      payload: data.announcement,
    });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const getCreateAnnouncementData = () => async dispatch => {
  dispatch(startLoading());

  try {
    const { data } = await api.get('/announcements/get-related-data');

    dispatch({
      type: SET_RELATED_DATA,
      payload: data,
    });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const fetchAllAnnouncements = filter => async dispatch => {
  dispatch(startLoading());

  try {
    const query = new URLSearchParams(filter).toString();

    const response = await api.get(`/announcements?${query}`);

    dispatch({ type: FETCH_ALL_ANNOUNCEMENTS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_ALL_ANNOUNCEMENTS_FAILURE, error });
  }
  dispatch(stopLoading());
};
