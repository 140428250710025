/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { fetchAll, fetchAllStats } from 'actions/Transactions';
import { useEffect } from 'react';
import { TRANSACTIONS } from 'constants/transactions';
// ['REPLENISH', 'BUY_PREMIUM', 'UNLOCK_ANNOUNCEMENT']
const useTransactions = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAll());
    dispatch(fetchAllStats());
  }, []);
  const {
    transactions,
    stats: {
      mostViewdAnnouncement,
      mostUnlockedAnnouncements,
      mostSpendingUsers,
      mostBuyingUsers,
      spendingCurve,
      buyingCurve,
    },
  } = useSelector(({ transactionsReducer }) => transactionsReducer);
  const consumedCreditsTransactions = transactions.filter(
    o =>
      o.transactionType === TRANSACTIONS.TYPE.BUY_PREMIUM ||
      o.transactionType === TRANSACTIONS.TYPE.UNLOCK_ANNOUNCEMENT,
  );

  const byCreditsTransactions = transactions.filter(o => o.transactionType === TRANSACTIONS.TYPE.REPLENISH);

  return {
    transactions,
    consumedCreditsTransactions,
    byCreditsTransactions,
    mostViewdAnnouncement:
      mostViewdAnnouncement &&
      mostViewdAnnouncement.map(o => {
        return {
          ...o,
          numberOfUnlocks:
            consumedCreditsTransactions && consumedCreditsTransactions.filter(f => f.announcement._id === o._id).length,
        };
      }),
    mostUnlockedAnnouncements,
    mostSpendingUsers,
    mostBuyingUsers,
    spendingCurve,
    buyingCurve,
    dataTableTransaction: prepareTransactionForDataTable(transactions),
  };
};
const prepareTransactionForDataTable = transactions =>
  transactions.map(o => ({
    user: `${o.user.firstName} ${o.user.lastName}`,
    userEmail: o.user.email,
    type: o.transactionType,
    transactionMethod: o.transactionMethod || 'N/A',
    admin: o.admin ? `${o.admin.firstName} ${o.admin.lastName}` : 'N/A',
    adminEmail: o.admin ? o.admin.email : 'N/A',
    createdAt: o.createdAt,
    transactionId: o.transactionId,
    amount: o.creditsAmmount,
    onlinePaymentStatus: o.onlinePaymentStatus,
  }));

export default useTransactions;
