import CachedIcon from '@material-ui/icons/Cached';
import { fetchAll, fetchAllStats } from 'actions/Transactions';
import orderBy from 'lodash/orderBy';
import getColumns from 'pages/HomePage/getColums';
import CustomStyles from 'pages/UserManagement/CustomStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import formatTransactionsToCSV from 'helpers/formatTransactionsToCSV';
import filterItems from './filterItems';

const TransactionsDataTable = ({ data }) => {
  const isLoading = useSelector(({ loading }) => loading.loading);

  const customSort = (rows, field, direction) => {
    if (!rows[0]) return [];
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    if (typeof field === 'function') {
      if (moment(field(rows[0]), 'LLL').isValid()) {
        return rows.sort((a, b) => {
          if (moment(a.createdAt).isAfter(moment(b.createdAt))) return direction === 'asc' ? 1 : -1;
          if (moment(a.createdAt).isBefore(moment(b.createdAt))) return direction === 'asc' ? -1 : 1;

          return 0;
        });
      }

      return orderBy(rows, row => field(row), direction);
    }
    if (typeof rows[0][field] === 'number') {
      return orderBy(rows, row => row[field], direction);
    }

    return orderBy(rows, handleField, direction);
  };

  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const dispatch = useDispatch();
  const columns = getColumns();

  useEffect(() => {
    setFilteredData(filterItems(data, filter));
  }, [data, filter]);

  const DataTableHeader = React.useMemo(
    () => (
      <div className="d-flex">
        <span className="d-flex pr-4 justify-content-between align-items-center">Recherchez dans la liste</span>
        <div className="input-group">
          <input
            onChange={e => setFilter(e.target.value)}
            value={filter}
            type="text"
            name="filter"
            className="form-control"
          />
          <div onClick={() => setFilter('')} className="input-group-append">
            <span className="input-group-text  bg-primary text-white" id="basic-addon2">
              X
            </span>
          </div>
        </div>
        <button
          className="btn  btn-outline-primary btn-icon  ml-4"
          type="button"
          onClick={() => {
            dispatch(fetchAll());
            dispatch(fetchAllStats());
          }}
        >
          <CachedIcon />
        </button>

        <CSVLink
          className="btn  btn-outline-primary btn-icon  ml-2 d-flex align-items-center justify-content-center"
          filename="Transactions.csv"
          data={formatTransactionsToCSV(data)}
        >
          <i className="far fa-save" />
        </CSVLink>
      </div>
    ),
    [dispatch, filter, data],
  );

  return (
    <div>
      <DataTable
        noHeader
        subHeader
        subHeaderComponent={DataTableHeader}
        columns={columns}
        data={filteredData}
        progressPending={isLoading}
        customStyles={CustomStyles}
        persistTableHead
        sortFunction={customSort}
        fixedHeaderScrollHeight="calc(100vh - 300px)"
        style={{ maxWidth: '77vw' }}
      />
    </div>
  );
};

TransactionsDataTable.propTypes = {
  data: PropTypes.array,
};

export default TransactionsDataTable;
