import { acceptExpertBySlug, fetchExpertBySlug, fetchPendingExpertsList } from 'actions/experts';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

export const useExpertPage = () => {
  const {
    params: { slug },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const isLoading = useAppSelector(({ loading }) => loading.loading);
  const seoData = useAppSelector(({ expert }) => ({
    title: expert.selectedExpert?.name,
    description: expert.selectedExpert?.description,
    image: expert.selectedExpert?.logo,
  }));

  useEffect(() => {
    dispatch(fetchExpertBySlug(slug));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);
  const navigatorRef = useRef(null);
  const scrollToTeam = () => {
    if (navigatorRef.current) {
      navigatorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const history = useHistory();
  const handleAcceptExpert = () => {
    dispatch(acceptExpertBySlug(slug));
    dispatch(fetchPendingExpertsList());
    history.push('/validate-experts');
  };

  return { isLoading, navigatorRef, scrollToTeam, seoData, handleAcceptExpert };
};
