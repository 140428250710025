import { FETCH_USERS, SET_USER_ACTIONS } from 'constants/types';

const initialState = {
  users: [],
  clients: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USERS:
      return { ...state, users: payload, clients: payload.filter(user => !user.isAdmin) };
    case SET_USER_ACTIONS:
      return { ...state, selectedUserActions: payload };

    default:
      return state;
  }
};
