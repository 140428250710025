import {
  FETCH_ANNOUNCEMENT_BY_SLUG,
  FETCH_UNVERIVIED_ANNONCES,
  SELECT_ANNOUNCEMENT,
  UPDATE_UNVERIVIED_ANNONCES,
  FETCH_ALL_ANNOUNCEMENTS_SUCCESS,
} from 'constants/types';

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_UNVERIVIED_ANNONCES:
      return { ...state, ...payload };
    case UPDATE_UNVERIVIED_ANNONCES:
      return {
        ...state,
        announcements: payload.announcements,
        page: payload.page,
        hasNextPage: payload.hasNextPage,
        totalItems: payload.totalItems,
        totalPages: payload.totalPages,
      };
    case FETCH_ANNOUNCEMENT_BY_SLUG:
      return { ...state, selectedAnnouncement: payload };
    case SELECT_ANNOUNCEMENT:
      return { ...state, selectedAnnouncement: payload };
    case FETCH_ALL_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        allAnnouncements: payload,
      };
    default:
      return state;
  }
};
