import { fetchExpertsList } from 'actions/experts';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useExpertList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchExpertsList());
  }, [dispatch]);
  const pageObject = useSelector(({ expertList: { list } }) => ({
    page: list?.page,
    totalPages: list?.totalPages,
    count: list?.totalItems,
    totalCount: list?.totalCount,
  }));

  return { pageObject };
};
