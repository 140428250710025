/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { addPictures, updateGeneralData, updatePhotosOrder } from 'actions/Annonces/update';
import { fetchAnnouncementBySlug, getCreateAnnouncementData } from 'actions/Annonces';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Collapsible from 'components/Collapsible';
import EditAnnouncementAddPicture from 'containers/EditAnnouncementAddPicture';
import EditAnnouncementGeneralInformationForm from 'containers/EditAnnouncementGeneralInformationForm';
import EditAnnouncementRearrangePhotos from 'containers/EditAnnouncementRearrangePhotos';
import FacebookTypes from 'constants/facebookTypes';
import SEO from 'components/SEO';
import _ from 'lodash';
import strings from 'constants/strings';

const EditAnnouncement = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector(({ loading }) => loading.loading);
  const selectedAnnouncement = useSelector(({ annonces }) => annonces.selectedAnnouncement);
  const relatedDataReducer = useSelector(({ relatedData }) => relatedData);

  useEffect(() => {
    dispatch(getCreateAnnouncementData());
  }, []);

  const { slug } = match.params;

  useEffect(() => {
    fetchBySlug(slug);
  }, [slug]);
  const fetchBySlug = async _slug => {
    const response = await dispatch(fetchAnnouncementBySlug(_slug));

    if (response === 404) history.push('/announcement-not-found');
  };

  const handleUpdatePictures = photoArray => {
    dispatch(updatePhotosOrder({ photos: photoArray }, selectedAnnouncement));
  };

  if (_.isEmpty(selectedAnnouncement) || isLoading) return <div />;

  return (
    <>
      <SEO
        title="Ajouter El Annonce"
        description="Ce formulaire est dediée pour ajouter des annonces à la plateforme elbusines.com"
        type={FacebookTypes.article}
      />
      <div className="container space-top-2">
        <Collapsible title={strings.userProfile.generalInformation}>
          <EditAnnouncementGeneralInformationForm
            initialValues={selectedAnnouncement}
            {...relatedDataReducer}
            onSubmit={values => dispatch(updateGeneralData(values, selectedAnnouncement))}
          />
        </Collapsible>
        <Collapsible title={strings.userProfile.reorderPhotos}>
          <EditAnnouncementRearrangePhotos photos={selectedAnnouncement.photos} onSubmit={handleUpdatePictures} />
        </Collapsible>
        <Collapsible title={strings.userProfile.addPhotos}>
          <EditAnnouncementAddPicture
            photos={selectedAnnouncement.photos}
            onSubmit={values => dispatch(addPictures(values, selectedAnnouncement))}
          />
        </Collapsible>
      </div>
    </>
  );
};

export default EditAnnouncement;
