import { CREATE_EXPERT, GET_ONE_EXPERT, GET_RELATED_EXPERT } from 'constants/types/expert';

export const authInitialState = {};

export default (state = authInitialState, { type, payload }) => {
  switch (type) {
    case CREATE_EXPERT:
      return { ...state, userExpert: payload };
    case GET_ONE_EXPERT:
      return { ...state, selectedExpert: payload };
    case GET_RELATED_EXPERT:
      return { ...state, relatedExperts: payload };
    default:
      return state;
  }
};
