import { GET_EXPERTS_LIST, GET_PENDING_EXPERTS_LIST } from 'constants/types/expert';

export const expertList = {
  isPredloaded: false,
  loaded: false,
};

export default (state = expertList, { type, payload }) => {
  switch (type) {
    case GET_EXPERTS_LIST:
      return { ...state, loaded: true, list: payload };
    case GET_PENDING_EXPERTS_LIST:
      return { ...state, loaded: true, ...payload };
    default:
      return state;
  }
};
