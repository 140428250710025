import PropTypes from 'prop-types';
import React from 'react';

const ModalFooter = ({ handleSubmit, onClose }) => {
  return (
    <>
      <button className="btn btn-soft-danger" onClick={onClose} type="button">
        Annuler
      </button>

      <button className="btn btn-soft-success" onClick={handleSubmit} type="button">
        Confirmer
      </button>
    </>
  );
};

ModalFooter.propTypes = {
  handleSubmit: PropTypes.func,
  onClose: PropTypes.func,
};
export default ModalFooter;
