import { startLoading, stopLoading } from 'actions/Loading';
import { errorHandler } from 'helpers/errorHandler';
import api from 'api';
import { FETCH_TRANSACTIONS, FETCH_STATS } from 'constants/types';

export const fetchAll = () => async dispatch => {
  dispatch(startLoading());
  try {
    const { data } = await api.get('/transactions');

    dispatch({ type: FETCH_TRANSACTIONS, payload: data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const fetchAllStats = () => async dispatch => {
  dispatch(startLoading());
  try {
    const { data } = await api.get('/stats');

    dispatch({ type: FETCH_STATS, payload: data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
