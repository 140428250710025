const CustomStyles = {
  headRow: {
    style: {
      border: 'none',
      maxWidth: '1200px',
    },
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
      maxWidth: '200px',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      borderRadius: '25px',
      outline: '1px solid #FFFFFF',
    },
  },
  pagination: {
    style: {
      border: 'none',
    },
  },
};

export default CustomStyles;
