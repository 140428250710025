import PropTypes from 'prop-types';
import React from 'react';

const MostStatCard = ({ children, title }) => {
  return (
    <div className="card card-frame card-frame-highlighted">
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        {children}
      </div>
    </div>
  );
};

MostStatCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default MostStatCard;
