import getImage from 'helpers/getImage';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Breadcrumbs from './Breadcrumbs';
import { ExpertHeaderBreadCrumbs } from './ExpertHeaderBreadCrumbs';

const ExpertCard = ({ address, name, logo, slug, category, website, subCategory, country, region, description }) => {
  return (
    <div className="col mb-5">
      <div className="card card-bordered h-100">
        <div className="card-body position-relative">
          <h3 className="card-title">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img className="avatar avatar-sm avatar-4x3" width={75} src={getImage(logo[0])} alt={name} />
                </div>
                <div className="pl-3">
                  <Link className="text-dark " to={`/expert/${slug}`}>
                    {name}
                  </Link>
                  <span className="text-info me-2">
                    <Breadcrumbs {...{ category, subCategory, isInList: true }} />
                  </span>
                </div>
              </div>
              <Link to={`/expert/${slug}`} className="btn btn-soft-primary">
                Voir plus
              </Link>
            </div>
          </h3>
          <p className="text-justify">{description}</p>
          <a
            href={website}
            target="__blank"
            className="font-weight-lighter font-italic position-absolute bottom-0 mb-2"
          >
            {website}
          </a>
        </div>
        <div className="card-footer">
          <ExpertHeaderBreadCrumbs {...{ country, region, address }} />
        </div>
      </div>
    </div>
  );
};

ExpertCard.propTypes = {
  address: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.array,
  slug: PropTypes.string,
  category: PropTypes.object,
  website: PropTypes.string,
  subCategory: PropTypes.object,
  country: PropTypes.object,
  region: PropTypes.object,
  description: PropTypes.string,
};

export default ExpertCard;
