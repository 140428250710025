import { ResponsiveLine } from '@nivo/line';
import React from 'react';
import PropTypes from 'prop-types';

const Chart = ({ data }) => {
  return (
    <>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%dT%H:%M:%S.%L%Z',
          precision: 'second',
        }}
        xFormat="time:%Y-%m-%dT%H:%M:%S.%L%Z"
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        curve="monotoneX"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: '%b %d %H:%M',
          tickValues: 'every 6 hours',
          legend: 'time scale',
          legendOffset: -12,
          legendPosition: 'middle',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Valeur',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        colors={{ scheme: 'spectral' }}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        areaBaselineValue={0}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-24}
        enableArea
        areaBlendMode="multiply"
        areaOpacity={0.3}
        enableSlices="x"
        useMesh
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 94,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </>
  );
};

Chart.propTypes = {
  data: PropTypes.array,
};
export default Chart;
