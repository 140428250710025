/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CURRENCY } from 'constants/currency';
import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import SelectField from 'components/SelectField';
import Validators from 'helpers/formValidator';
import { change } from 'redux-form';
import strings from 'constants/strings';

const reformatUnits = (unitsArray, selectedCurrency) => {
  return unitsArray.map(element => {
    const modifiedUnit = element.replace('unit', selectedCurrency);

    return { label: modifiedUnit, value: modifiedUnit };
  });
};

const FinancialFields = ({ financialData, formName, country }) => {
  const { title, required, annualIncrease, units } = financialData;
  const [selectedUnit, setSelectedUnit] = useState();
  const dispatch = useDispatch();
  const { selectedCurrency } = useSelector(state => state.form[formName].values);

  useEffect(() => {
    if (!selectedCurrency)
      dispatch(change(formName, 'selectedCurrency', CURRENCY.find(el => el.country === country.title)?.unit.value));
  }, []);

  useEffect(() => {
    setSelectedUnit(undefined);
  }, [financialData]);

  const handleSelectedUnit = value => {
    setSelectedUnit(value.value);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 mb-3">
          <SelectField
            options={CURRENCY.map(el => el.unit)}
            label={strings.addAnnouncementExtraFieldCurrencyLabel}
            placeholder={strings.addAnnouncementExtraFieldCurrencyPlaceholder}
            name="selectedCurrency"
            validate={required ? [Validators.required] : undefined}
          />
        </div>
        <div className="col-md-6 mb-3">
          <SelectField
            options={reformatUnits(units, selectedCurrency)}
            label={strings.addAnnouncementUnitLabel}
            placeholder={strings.addAnnouncementUnitPlaceholder}
            name="financial.unit"
            validate={required ? [Validators.required] : undefined}
            getSelectedValue={handleSelectedUnit}
          />
        </div>
        <div className="col-md-6 mb-3">
          <InputField
            label={title}
            placeholder={title}
            name="financial.value"
            validate={required ? [Validators.required, Validators.numeric] : [Validators.numeric]}
            rightIcon={selectedUnit}
          />
        </div>
      </div>
      {annualIncrease && (
        <div className="row">
          <div className="col-md-6 mb-3">
            <InputField
              label={strings.addAnnouncementAnnualIncreaseLabel}
              placeholder={strings.addAnnouncementAnnualIncreasePlaceholder}
              name="annualIncrease"
              validate={[Validators.required, Validators.numeric]}
              rightIcon="%"
            />
          </div>
        </div>
      )}
    </>
  );
};

FinancialFields.propTypes = {
  financialData: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default FinancialFields;
