import moment from 'moment';
import React from 'react';

const getColumns = () => {
  const translateTransactionType = s => {
    // type: { String, enum: ['REPLENISH', 'BUY_PREMIUM', 'UNLOCK_ANNOUNCEMENT'] },

    switch (s) {
      case 'REPLENISH':
        return <span className="p-1 badge badge-pill badge-primary badge-bigger">Achat de credits</span>;
      case 'BUY_PREMIUM':
        return <span className="p-1 badge badge-pill badge-success badge-bigger">Boost d&apos;annonce</span>;
      case 'UNLOCK_ANNOUNCEMENT':
        return <span className="p-1 badge badge-pill badge-success badge-bigger">Devoiler une annonce</span>;
      case 'TWELVE_MONTH_PLAN':
        return <span className="p-1 badge badge-pill badge-info badge-bigger">Plan d&apos;expert de 12 mois</span>;
      case 'SIX_MONTH_PLAN':
        return <span className="p-1 badge badge-pill badge-info badge-bigger">Plan d&apos;expert de 6 mois</span>;

      default:
        return <span className="p-1 badge badge-pill badge-grey badge-bigger">N/A</span>;
    }
  };
  const translateTransactionMethod = (method, status) => {
    // type: { String, enum: ['REPLENISH', 'BUY_PREMIUM', 'UNLOCK_ANNOUNCEMENT'] },
    // onlinePaymentStatus : ['SUCCESS', 'DECLINED' , 'ON_STAND_BY']

    let onlinePaymentStatusText = '';

    let onlineBadgeColor = '';

    switch (status) {
      case 'SUCCESS':
        onlinePaymentStatusText = 'avec succés';
        onlineBadgeColor = 'success';
        break;
      case 'DECLINED':
        onlinePaymentStatusText = 'échoué';
        onlineBadgeColor = 'warning';
        break;
      case 'ON_STAND_BY':
        onlinePaymentStatusText = 'en attente';
        onlineBadgeColor = 'info';
        break;

      default:
        onlinePaymentStatusText = '';
        onlineBadgeColor = 'info';
        break;
    }

    switch (method) {
      case 'ADMIN':
        return <span className="p-1 badge badge-pill badge-danger badge-bigger">Par un administrateur</span>;
      case 'ONLINE_PAYMENT':
        return (
          <span className={`p-1 badge badge-pill badge-${onlineBadgeColor} badge-bigger`}>
            {`Achat online ${onlinePaymentStatusText}`}
          </span>
        );
      default:
        return <span className="p-1 badge badge-pill badge-grey badge-bigger">N/A</span>;
    }
  };
  const columns = [
    {
      name: 'Utilisateur',
      selector: 'user',
      sortable: true,
      wrap: true,
    },
    {
      name: "Email de l'utilisateur",
      selector: 'userEmail',
      wrap: true,
      sortable: true,
    },
    {
      name: 'Type de transaction',
      selector: 'type',
      wrap: true,
      sortable: true,
      cell: row => translateTransactionType(row.type),
    },
    {
      name: 'Nombre de crédits',
      selector: 'amount',
      sortable: true,
      wrap: true,
    },
    {
      name: "Méthode d'achat",
      selector: 'transactionMethod',
      sortable: true,
      wrap: true,
      cell: row => translateTransactionMethod(row.transactionMethod, row.onlinePaymentStatus),
    },
    {
      name: 'Admin responsable',
      selector: 'admin',
      sortable: true,
      wrap: true,
    },
    {
      name: "Email de l'admin responsable",
      selector: 'adminEmail',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Ref de transaction',
      selector: 'transactionId',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Date de la transaction',
      selector: row => moment(row.createdAt).format('LLL'),
      sortable: true,
      wrap: true,
    },
  ];

  return columns;
};

export default getColumns;
