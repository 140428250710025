import { fetchAllAnnouncements } from 'actions/Annonces';
import { deleteAnnounce } from 'actions/Annonces/update';
import AnnouncementCard from 'components/AnnoucementCard';
import Pagination from 'components/Pagination';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const VerifiedAnnouncements = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { allAnnouncements } = useSelector(state => state.annonces);

  useEffect(() => {
    dispatch(fetchAllAnnouncements(search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handlePageChange = newPage => {
    const newQuery = new URLSearchParams({
      page: newPage,
    }).toString();

    history.push(`/annonces?${newQuery}`);
  };

  return (
    <div>
      <div className="container">
        {allAnnouncements && (
          <>
            {allAnnouncements.announcements.map(announcement => (
              <AnnouncementCard
                announcement={announcement}
                key={announcement._id}
                onEdit={() => history.push(`/modifier-annonce/${announcement.slug}`)}
                onDelete={() => dispatch(deleteAnnounce(announcement))}
              />
            ))}
            <Pagination
              page={allAnnouncements.page}
              totalPages={allAnnouncements.totalPages}
              hasNextPage={allAnnouncements.hasNextPage}
              handlePress={handlePageChange}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default VerifiedAnnouncements;
