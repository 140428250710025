/* eslint-disable jsx-a11y/control-has-associated-label */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import getImage from 'helpers/getImage';

const SliderArrow = ({ style, ...props }) => {
  return <div style={{ ...style, display: 'block', filter: 'invert(100%)' }} {...props} />;
};

SliderArrow.propTypes = {
  style: PropTypes.object,
};

const AnnouncementSlider = ({ photos }) => {
  return (
    <Slider dots infinite nextArrow={<SliderArrow />} prevArrow={<SliderArrow />}>
      {photos.map(element => {
        return <img src={getImage(element)} className="slider-image" alt="slider" key={element} />;
      })}
    </Slider>
  );
};

AnnouncementSlider.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default AnnouncementSlider;
