import { Contact, Description, Details, Header, ImageSlider, Optional } from 'components/Announcement';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

const index = ({ selectedAnnouncement, isLogged, history }) => {
  return (
    <div className="container-fluid position-relative p-2 mb-3">
      <div className="container space-bottom-2">
        <Header {...selectedAnnouncement} />
        <div className="row-inverted-mobile  space-top-1">
          <div className="col-lg-8 mb-9 mb-lg-0 pr-5">
            <ImageSlider photos={selectedAnnouncement.photos} />
            <div className="tab-content">
              <div className="fade pt-6 show active" id="pills-one" role="tabpanel" aria-labelledby="pills-one-tab">
                <Details {...selectedAnnouncement} />
                {selectedAnnouncement.extraData && !_.isEmpty(selectedAnnouncement.extraData) && (
                  <Optional
                    selectedCategory={selectedAnnouncement.category.title}
                    extraData={selectedAnnouncement.extraData}
                    extraInfo={selectedAnnouncement.extraInfo}
                  />
                )}
                <Description description={selectedAnnouncement.description} />
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <Contact
              {...selectedAnnouncement.addedBy}
              annoucement={selectedAnnouncement}
              isLogged={isLogged}
              history={history}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

index.propTypes = {
  selectedAnnouncement: PropTypes.object.isRequired,
  isLogged: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

export default index;
