import PropTypes from 'prop-types';
import React from 'react';

const Pagination = ({ page, totalPages, hasNextPage, handlePress }) => {
  const iterator = Array(totalPages).fill(null);

  return (
    <div className="d-flex justify-content-center my-3   ">
      <ul className="pagination mb-0">
        {page > 1 && (
          <li className="page-item ml-0">
            <button type="button" className="page-link" onClick={() => handlePress(page - 1)} aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
              <span className="sr-only">Previous</span>
            </button>
          </li>
        )}
        {iterator.map((element, index) => (
          <div key={`id ${index + 1}`}>
            <li className={`page-item ${index + 1 === page && 'active'}`}>
              <button
                type="button"
                disabled={index + 1 === page}
                className="page-link"
                onClick={() => handlePress(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          </div>
        ))}
        {hasNextPage && (
          <li className="page-item">
            <button type="button" className="page-link" onClick={() => handlePress(page + 1)} aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
              <span className="sr-only">Next</span>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number,
  totalPages: PropTypes.number,
  hasNextPage: PropTypes.bool,
  handlePress: PropTypes.func.isRequired,
};

export default Pagination;
