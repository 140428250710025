import PropTypes from 'prop-types';
import React from 'react';

const StatCardElement = ({
  image,
  title,
  iconOneText,
  iconTwoText,
  iconOneFontAwsome,
  iconTwoFontAwsome,
  subtitle,
}) => {
  return (
    <div className="row pt-2">
      <div className="col-sm-5 col-lg-3 mb-3 mb-sm-0">
        <img className="img-fluid rounded" src={image} alt="Description" />
      </div>
      <div className="col-sm-7 col-lg-9">
        <div className="row">
          <div className="col-lg-6 mb-2 mb-lg-0">
            <h5 className="text-dark text-hover-primary">{title}</h5>
            <small className="d-block mt-n2 text-secondary">{subtitle}</small>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-12">
                <div className="small text-muted mb-2">
                  <i className={`${iconOneFontAwsome} mr-1`} />
                  {iconOneText}
                </div>
                <div className="small text-muted">
                  <i className={`${iconTwoFontAwsome} mr-1`} />
                  {iconTwoText}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StatCardElement.propTypes = {
  subtitle: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  iconOneText: PropTypes.string,
  iconTwoText: PropTypes.string,
  iconOneFontAwsome: PropTypes.string,
  iconTwoFontAwsome: PropTypes.string,
};
export default StatCardElement;
