/* eslint-disable max-len */
import { Link } from 'react-router-dom';
import React from 'react';

const AuthSidebar = () => {
  return (
    <div className="col-lg-5 col-xl-4 position-fixed left-0 top-0 d-none d-lg-flex align-items-center gradient-half-primary-v0 height-lg-100vh px-0">
      <div className="w-100 p-5">
        <figure className="text-center mb-5 mx-auto">
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 8 8"
            style={{ enableBackground: 'new 0 0 8 8' }}
            xmlSpace="preserve"
          >
            <path
              className="fill-white"
              d="M3,1.3C2,1.7,1.2,2.7,1.2,3.6c0,0.2,0,0.4,0.1,0.5c0.2-0.2,0.5-0.3,0.9-0.3c0.8,0,1.5,0.6,1.5,1.5c0,0.9-0.7,1.5-1.5,1.5
              C1.4,6.9,1,6.6,0.7,6.1C0.4,5.6,0.3,4.9,0.3,4.5c0-1.6,0.8-2.9,2.5-3.7L3,1.3z M7.1,1.3c-1,0.4-1.8,1.4-1.8,2.3
              c0,0.2,0,0.4,0.1,0.5c0.2-0.2,0.5-0.3,0.9-0.3c0.8,0,1.5,0.6,1.5,1.5c0,0.9-0.7,1.5-1.5,1.5c-0.7,0-1.1-0.3-1.4-0.8
              C4.4,5.6,4.4,4.9,4.4,4.5c0-1.6,0.8-2.9,2.5-3.7L7.1,1.3z"
            />
          </svg> */}
          <img alt="ElBusiness" src="/images/logo-mini-white.png" width="30%" />
        </figure>
        <div className=" mb-4">
          <div>
            <div className="w-md-80 w-lg-60 text-center mx-auto">
              <Link to="/">
                <blockquote className="h5 text-white font-weight-normal mb-4">In business we trust!</blockquote>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthSidebar;
