import moment from 'moment';
import formatToCsv from './formatToCsv';

const formatTransactionsToCSV = transactions =>
  formatToCsv(transactions, [
    {
      label: 'Utilisateur',
      value: 'user',
    },
    {
      label: "Email de l'utilisateur",
      value: 'userEmail',
    },
    {
      label: 'Type de transaction',
      value: 'type',
      transform: type => {
        switch (type) {
          case 'REPLENISH':
            return 'Achat de credits';
          case 'BUY_PREMIUM':
            return "Boost d'annonce";
          case 'UNLOCK_ANNOUNCEMENT':
            return 'Devoiler une annonce';

          default:
            return 'N/A';
        }
      },
    },
    {
      label: 'Nombre de crédits',
      value: 'amount',
    },
    {
      label: "Méthode d'achat",
      value: 'transactionMethod',
      transform: (method, { onlinePaymentStatus: status }) => {
        let onlinePaymentStatusText = '';

        switch (status) {
          case 'SUCCESS':
            onlinePaymentStatusText = 'avec succés';
            break;
          case 'DECLINED':
            onlinePaymentStatusText = 'échoué';
            break;
          case 'ON_STAND_BY':
            onlinePaymentStatusText = 'en attente';
            break;

          default:
            onlinePaymentStatusText = '';
            break;
        }

        switch (method) {
          case 'ADMIN':
            return 'Par un administrateur';
          case 'ONLINE_PAYMENT':
            return `Achat online ${onlinePaymentStatusText}`;
          default:
            return 'N/A';
        }
      },
    },
    {
      label: 'Admin responsable',
      value: 'admin',
    },
    {
      label: "Email de l'admin responsable",
      value: 'adminEmail',
    },
    {
      label: 'Ref de transaction',
      value: 'transactionId',
    },
    {
      label: 'Date de la transaction',
      value: 'createdAt',
      transform: createdAt => moment(createdAt).format('LLL'),
    },
  ]);

export default formatTransactionsToCSV;
