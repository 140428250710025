import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Collapse from '@material-ui/core/Collapse';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GroupIcon from '@material-ui/icons/Group';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PostAddIcon from '@material-ui/icons/PostAdd';
import React from 'react';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SidebarLink from 'components/Sidebar/SidebarLink';
import { useSelector } from 'react-redux';
import PeopleAltOutlined from '@material-ui/icons/PeopleAltOutlined';

const SidebarLinks = () => {
  const unverifiedAnnonces = useSelector(({ annonces }) => annonces.totalItems);
  const pendingExperts = useSelector(({ expertList }) => expertList.totalItems);

  const [openUserManagementCollapse, setOpenUserManagementCollapse] = React.useState(false);

  const handleClick = () => {
    setOpenUserManagementCollapse(!openUserManagementCollapse);
  };

  return (
    <List>
      <SidebarLink path="/" Icon={DashboardIcon} name="Dashboard" />

      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText className="text-black-50" primary="Gérer les utilisateurs" />
        {openUserManagementCollapse ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openUserManagementCollapse} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <SidebarLink path="/users" Icon={PeopleAltOutlined} name="Liste des utilisateurs" />
          <SidebarLink path="/add-credits" Icon={AttachMoneyIcon} name="Attribuer des crédits" />
          <SidebarLink path="/upgrade-role" Icon={AssignmentIndIcon} name="Ajouter des Administrateurs" />
          <SidebarLink
            path="/downgrade-role"
            Icon={AssignmentReturnedIcon}
            name="Révoquer les droits d'administrateur"
          />
          <SidebarLink path="/unban-user" Icon={AddCircleOutlineIcon} name="Amnistier un utilisateur" />
          <SidebarLink path="/ban-user" Icon={RemoveCircleOutlineIcon} name="Banir un utilisateur" />
        </List>
      </Collapse>
      <SidebarLink
        badge
        notifications={unverifiedAnnonces}
        path="/validate-annoucements"
        Icon={PostAddIcon}
        name="Gérer les annonces"
      />
      <SidebarLink path="/annonces" Icon={PostAddIcon} name="Annonces vérifiées" />
      <SidebarLink
        badge
        notifications={pendingExperts}
        path="/validate-experts"
        Icon={GroupIcon}
        name="Experts en attente"
      />
      {/* <SidebarLink path="/experts" Icon={GroupIcon} name="Liste des experts" /> */}
    </List>
  );
};

export default SidebarLinks;
