export const formatDataForChart = data => {
  const values = [
    { id: 'Nombre de Credits', data: data.map(o => ({ x: o.date, y: o.value })) },
    {
      id: 'Nombre de transaction',
      data: data.map(o => ({ x: o.date, y: o.numberOfTransactions })),
    },
  ];
  const firstDate = new Date(values[0].data[0].x);

  values[0].data = [{ x: new Date(firstDate.setHours(0)).toISOString(), y: 0 }, ...values[0].data];
  values[1].data = [{ x: new Date(firstDate.setHours(0)).toISOString(), y: 0 }, ...values[1].data];

  return values;
};
