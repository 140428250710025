import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';

const AddCreditsModal = ({ handleSubmit, onClose, isOpen, isCredits }) => {
  const [value, setValue] = useState(0);
  const [transactionId, setTransactionId] = useState('');
  const [bankName, setBankName] = useState('');
  const _handleSubmit = () => {
    if (!isCredits) handleSubmit();
    if (value.length && transactionId.trim().length && bankName.trim().length)
      handleSubmit(value, `${bankName.trim().split(' ').join('-').toUpperCase()}-${transactionId}`);
  };

  return (
    <Modal
      title={<ModalTitle />}
      isOpen={isOpen}
      footer={<ModalFooter handleSubmit={_handleSubmit} onClose={onClose} />}
    >
      {isCredits && (
        <>
          <div className="form-group">
            <label className="form-label">
              <span className="d-flex justify-content-between align-items-center">
                Le nombre de crédits à attribuer
              </span>
            </label>
            <input type="number" className="form-control" value={value} onChange={e => setValue(e.target.value)} />
          </div>
          <div className="form-group">
            <label className="form-label">
              <span className="d-flex justify-content-between align-items-center">
                Le code de virement ou numéro de chèque
              </span>
            </label>
            <input
              type="text"
              className="form-control"
              value={transactionId}
              onChange={e => setTransactionId(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="form-label">
              <span className="d-flex justify-content-between align-items-center">Le nom de la banque</span>
            </label>
            <input type="text" className="form-control" value={bankName} onChange={e => setBankName(e.target.value)} />
          </div>
        </>
      )}
    </Modal>
  );
};

AddCreditsModal.propTypes = {
  handleSubmit: PropTypes.func,
  isCredits: PropTypes.bool,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default AddCreditsModal;
