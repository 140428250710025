export const CURRENCY = [
  {
    country: 'Tunisie',
    unit: {
      label: 'DT',
      value: 'DT',
    },
  },
  {
    country: 'Libye',
    unit: {
      label: 'LYD',
      value: 'LYD',
    },
  },
  {
    country: 'Algérie',
    unit: {
      label: 'DZD',
      value: 'DZD',
    },
  },
  {
    country: 'Maroc',
    unit: {
      label: 'MAD',
      value: 'MAD',
    },
  },
  {
    country: 'Dollar',
    unit: {
      label: '$',
      value: '$',
    },
  },
  {
    country: 'Euro',
    unit: {
      label: '€',
      value: '€',
    },
  },
];
