/* eslint-disable max-lines */
/* eslint-disable max-len */
const strings = {
  welcome: 'Bienvenue sur',
  elBusines: 'ElBusines',
  signUpFormHeader: 'Remplissez le formulaire pour commencer',
  signUpEmailLabel: 'Adresse Email',
  signUpEmailPlaceholder: 'Adresse Email',
  signUpLastNameLabel: 'Votre nom',
  signUpLastNamePlaceholder: 'Nom',
  signUpFirstNameLabel: 'Votre prénom',
  signUpFirstNamePlaceholder: 'Prénom',
  signUpCompanyNameLabel: 'Votre entreprise',
  signUpCompanyNamePlaceholder: 'Entreprise',
  signUpPhoneNumberLabel: 'Votre N° de téléphone',
  signUpPhoneNumberPlaceholder: 'Téléphone',
  signUpCountryLabel: 'Votre pays',
  signUpCountryPlaceholder: 'Votre pays',
  signUpTypeLabel: 'Vous êtes plutôt',
  signUpTypePlaceholder: 'Vous êtes plutôt',
  signUpDescriptionLabel: 'Description (Optionnelle)',
  signUpDescriptionPlaceholder: 'Description ...',
  signUpPasswordLabel: 'Mot de passe',
  signUpPasswordPlaceholder: '••••••••',
  signUpPasswordConfirmationLabel: 'Confirmer votre mot de passe',
  signUpPasswordConfirmationPlaceholder: '••••••••',
  signUpAvatarLabel: 'Votre photo de profil',
  signUpLoginQuestion: 'Vous avez un compte?',
  signIn: 'connectez-vous',
  signUp: 'Inscrivez-vous',
  _1conditionsRead: 'Je reconnais avoir lu les',
  _2conditionsRead: "Conditions Générales d'utilisation du site",
  _3conditionsRead: 'et les accepte',
  forgotPassword: 'Mot de passe oublié?',
  signInSignUpQuestion: "Vous n'avez pas de compte?",
  start: 'Commencer',
  addAnnouncementFormTitle: 'Créer une annonce',
  addAnnouncementFormHeader: 'Veuillez remplir le formulaire suivant afin de créer votre annonce',
  addAnnouncementTitleLabel: 'Titre',
  addAnnouncementTitlePlaceholder: 'Ajouter un titre descriptif',
  addAnnouncementDescriptionLabel: 'Description',
  addAnnouncementDescriptionPlaceholder: 'Description',
  addAnnouncementCategoryLabel: 'Categorie',
  addAnnouncementCategoryPlaceholder: 'Categorie',
  addAnnouncementSubCategoryLabel: 'Sous-Categorie',
  addAnnouncementSubCategoryPlaceholder: 'Sous-Categorie',
  addAnnouncementDurationLabel: 'Durée',
  addAnnouncementDurationPlaceholder: 'Max 90',
  addAnnouncementDurationUnit: 'Jours',
  addAnnouncementCountryLabel: 'Pays',
  addAnnouncementCountryPlaceholder: 'Pays',
  addAnnouncementRegionLabel: 'Région',
  addAnnouncementRegionPlaceholder: 'Région',
  addAnnouncementAddressLabel: 'Adresse',
  addAnnouncementAddressPlaceholder: 'Adresse',
  addAnnouncementExtraFieldTitleLabel: 'Titre',
  addAnnouncementExtraFieldTitlePlaceholder: 'Titre',
  addAnnouncementExtraFieldValueLabel: 'Valeur',
  addAnnouncementExtraFieldValuePlaceholder: 'Valeur',
  addAnnouncementExtraFieldUnitLabel: 'Unité',
  addAnnouncementExtraFieldUnitPlaceholder: 'Unité',
  addAnnouncementExtraFieldCurrencyLabel: 'Devise',
  addAnnouncementExtraFieldCurrencyPlaceholder: 'Devise',
  addAnnouncementIncentivesLabel: 'Régimes d’incitations ',
  addAnnouncementIncentivesPlaceholder: 'Régimes d’incitations ',
  addAnnouncementAddExtraField: "Ajouter plus d'informations",
  addAnnouncementRemoveExtraFieldSection: "Moins d'informations",
  addAnnouncementRemoveExtraField: 'Annuler',
  imageUploadDefaultMessage: 'Choisissez au moins une image',
  imageUploadRemoveImage: 'Annuler',
  addAnnouncementSubmit: 'Ajouter votre annonce',
  headerStrings: {
    entreprisesEtCommerces: 'Entreprise et Commerce',
    immobilierPro: 'Immobilier Professionnel',
    investissementEtPart: 'Investissement et Partenariat',
    seConnecter: 'Se Connecter',
    seDeconnecter: 'Se Déconnecter',
  },
  addAnnouncementUnitLabel: 'Unité',
  addAnnouncementUnitPlaceholder: 'Unité',
  addAnnouncementAnnualIncreaseLabel: 'Augmentation annuelle',
  addAnnouncementAnnualIncreasePlaceholder: '3',
  addAnnouncementSituationLabel: 'Situation',
  addAnnouncementSituationPLaceholder: 'Situation',
  addAnnouncementSurfaceLabel: 'Surface',
  addAnnouncementSurfacePLaceholder: 'Surface',
  addAnnouncementSurfaceUnit: 'm²',
  addAnnouncementActivityLabel: 'Activité',
  addAnnouncementActivityPLaceholder: 'Activité',
  addAnnouncementSubActivityLabel: 'Sous-Activité',
  addAnnouncementSubActivityPLaceholder: 'Sous-Activité',
  addAnnouncementRealEstateTypeLabel: 'Type de bien',
  addAnnouncementRealEstateTypePLaceholder: 'Type de bien',
  addAnnouncementOpportunityLabel: 'Opportunité',
  addAnnouncementOpportunityPLaceholder: 'Opportunité',
  addAnnouncementSubOpportunityLabel: 'Type',
  addAnnouncementSubOpportunityPLaceholder: 'Type',
  announcementViewsPlural: 'Vues',
  announcementViewsSingular: 'Vue',
  announcementMoreInformations: 'Informations supplémentaires',
  editAnnouncementFormTitle: 'Modifier une annonce',
  editAnnouncementFormHeader:
    'Vous pouvez modifier les différentes informations de votre annonce dans le formulaire ci-dessous.',
  filterStrings: {
    dateLabel: 'Créée après le',
    searchSubmit: 'Rechercher',
    price: 'Prix',
    searchLabel: 'Recherche',
    searchPlaceholder: 'Recherche',
    categoryLabel: 'Categorie',
  },
  announcementCard: {
    details: 'Détails',
    postedOn: 'Publiée le',
    edit: 'Modifier',
    boost: 'Boost',
    expireLe: 'Sponsorisation expire',
    daysAfterValidation: 'jours (après validation)',
  },
  extraDataFieldsTitles: {
    chiffresAffaires: "Chiffres d'affaires",
    chargesExploitation: "Charges d'exploitation",
    chargesPersonnel: 'Charges personnel',
    resultatNet: 'Résultat Net',
    nombrePersonnel: "Nombre d'employés",
    revenus: 'Revenus',
    charges: 'Charges',
    capitalSocial: 'Capital Social',
    totalBilan: 'Total Bilan',
    extraInfo: "Plus d'information",
  },
  announcementID: 'ID',
  announcements: 'Annonces',
  informations: 'Informations',
  description: 'Description',
  similarAnnouncements: 'Annonces similaires',
  formFooter: 'champs obligatoires',
  userProfile: {
    maxFilesReached:
      "Vous avez atteint le maximum nombre d'images, veuillez en supprimer afin de pouvoir ajouter de nouvelles photos.",
    myProfile: 'Mon profil',
    saveChanges: 'Enregistrer les modifications',
    generalInformation: 'Informations générales',
    reorderPhotos: 'Ordre des images',
    reorderPhotosText: 'Glissez les images par ordre de priorité',
    addPhotos: 'Ajouter des photos',
    userInformations: 'Informations personnelles',
    changePassword: 'Modification de mot de passe',
    userAnnouncements: 'Annonces publiées',
    noAnnouncements: "Vous n'avez pas encore publié d'annonce",
    oldPasswordLabel: 'Mot de passe actuel',
    newPasswordLabel: 'Nouveau mot de passe',
    newPasswordConfirmationLabel: 'Confirmer le mot de passe',
    firstName: 'Prénom',
    lastName: 'Nom',
    phoneNumber: 'N° de téléphone',
    companyName: 'Entreprise',
    userType: "Type d'utilisateur",
    country: 'Pays',
    announcementNumber: "N° d'annonces",
    accountCreatedAt: 'Compte créé',
    accountCreatedAtPrefix: 'Le',
    credits: 'Crédits El Busines',
    addCredits: 'Ajouter des crédits',
    resetPasswordSuccessMessage: 'Votre mot de passe a été modifié avec succès.',
  },
  packs: {
    title: 'Ajouter des crédits',
    starterPack: 'Starter',
    standardPack: 'Basic',
    advancedPack: 'Professional',
    platinumPack: 'Premium',
    credits: 'crédits',
    buyFor: 'Acheter à',
  },
  verifyPayment: {
    paymentSuccessTitle: 'Votre transaction a étée effectuée avec succès',
    payementFailureTitle: "Votre transaction n'a pas été effectuée ",
    transactionDetails: 'Détails de la transaction',
    amount: 'Montant en TND',
    credits: 'Crédits achetés',
    date: 'Date de transaction',
    backToProfile: 'Retour au profil',
  },
  boostAnnouncement: {
    notEnoughCredits: 'Pas assez de crédits',
    confirm: 'Confirmer',
    availableCredits: 'crédits disponibles',
    title: 'Boostez votre annonce',
    free: {
      title: 'Free',
      subTitle: 'Déposer votre annonce gratuitement et bénéficier de notre réseau d’affaires.',
      type1: {
        feature1: '7 jours',
        feature2: '2$ Ad',
      },
    },
    tier2: {
      title: 'Starter',
      subTitle:
        'Bénéficier d’un emplacement de privilège de votre annonce sur le site ElBusines.com et une présence en avant sur les bannières du site.',
      type1: {
        feature1: '7 jours',
      },
      type2: {
        feature1: '15 jours',
      },
    },
    tier1: {
      title: 'Premium',
      subTitle:
        'Bénéficier d’un emplacement VIP sur le site ElBusine.com et d’une promotion de votre annonce sur les réseaux sociaux (Facebook et LinkedIn) pour atteindre le maximum de personnes et avoir une meilleure visibilité.',
      type1: {
        feature1: '7 jours',
        feature2: 'Facebook, LinkedIn',
      },
      type2: {
        feature1: '15 jours',
        feature2: 'Facebook, LinkedIn',
      },
    },
  },
  homePage: {
    entreprisesEtCommerces: 'Annonces Privilèges en Entrepise et Commerce',
    immobilierPro: 'Annonces Privilèges en Immobilier Professoinnel',
    investissementEtPart: 'Annonces Privilèges en Investissement et Partenariat',
    noticeTitle: 'Qui sommes nous',
    noticeParagraph1:
      "ElBusines.com, le site maghrébin d'annonces de vente et achat d'entreprises, TPE, PME, commerces, fonds de commerce et immobilier entreprise.",
    noticeParagraph2:
      "Consultez les milliers d'affaires à vendre en Libye, Tunisie, Algérie et Maroc dans le secteur de l'industrie, l'hôtellerie, la restauration mais aussi l'immobilier professionnel avec les locaux commerciaux, bureaux, immeubles commerciaux en vente, en location et en cession droit au bail.",
    noticeParagraph3:
      "Les annonces proviennent des institutions financières, cabinets d'affaires, cabinets d'intermédiation, agences immobilières, acquisition, transmission d'entreprises et de commerces, d'immobilier professionnel et d'entreprise ainsi que de chefs d'entreprises souhaitant céder une affaire.",
  },
  resetPassword: {
    title: 'Rénitialisation de mot de passe.',
    successToast: 'Votre mot de passe a été réinitalisé avec succès. Veuillez vous reconnecter.',
  },
  forgotPasswordPage: {
    title: 'Mot de passe oublié.',
    successToast:
      'Un email vous a été envoyé contentant les informations nécessaire pour réinitaliser votre mot de passe.',
  },
};

export default strings;
