import formatToCsv from './formatToCsv';

const formatTopUsersToCSV = users =>
  formatToCsv(users, [
    {
      label: 'Nom',
      value: 'lastName',
    },
    {
      label: 'Prénom',
      value: 'firstName',
    },
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'N° Téléphone',
      value: 'phoneNumber',
    },
    {
      label: 'Crédits achetés',
      value: 'buy',
    },
    {
      label: 'Crédits dépensés',
      value: 'spend',
    },
    {
      label: 'Pays',
      value: 'country',
    },
    {
      label: "Type d'utilisateur",
      value: 'type.title',
    },
    {
      label: 'Société',
      value: 'companyName',
    },
  ]);

export default formatTopUsersToCSV;
