import InputField from 'components/InputField';
import React from 'react';
import SelectField from 'components/SelectField';
import Validators from 'helpers/formValidator';
import { VariableFieldsPropTypes } from 'containers/AnnouncementForm/propTypes';
import categoryNames from 'constants/categoryNames';
import situations from 'constants/situations';
import strings from 'constants/strings';

const VariableFields = ({
  selectedCategory,
  activities,
  subActivities,
  handleActivityChange,
  realEstateTypes,
  opportunities,
  handleOpportunityChange,
  subOpportunities,
  incentives,
}) => {
  if (!selectedCategory) return null;
  switch (selectedCategory.title) {
    case categoryNames.CATEGORY_ENTREPRISE:
    case categoryNames.CATEGORY_IMMOBILIER:
      return (
        <>
          <div className="row mt-4">
            <div className="col-md-6 mb-3">
              <InputField
                label={strings.addAnnouncementSurfaceLabel}
                placeholder={strings.addAnnouncementSurfacePLaceholder}
                rightIcon={strings.addAnnouncementSurfaceUnit}
                name="surface"
                validate={[Validators.required, Validators.numeric]}
              />
            </div>
            <div className="col-md-6 mb-3">
              <SelectField
                options={situations}
                label={strings.addAnnouncementSituationLabel}
                placeholder={strings.addAnnouncementSituationPLaceholder}
                name="situation"
                validate={[Validators.required]}
              />
            </div>
          </div>
          <div className="row">
            {selectedCategory.title === categoryNames.CATEGORY_ENTREPRISE ? (
              <>
                <div className="col-md-6 mb-3">
                  <SelectField
                    options={activities}
                    label={strings.addAnnouncementActivityLabel}
                    placeholder={strings.addAnnouncementActivityPLaceholder}
                    name="activity"
                    getSelectedValue={handleActivityChange}
                    validate={[Validators.required]}
                  />
                </div>
                {subActivities.length > 0 && (
                  <div className="col-md-6 mb-3">
                    <SelectField
                      options={subActivities}
                      label={strings.addAnnouncementSubActivityLabel}
                      placeholder={strings.addAnnouncementSubActivityPLaceholder}
                      name="subActivity"
                      key="subActivity"
                      validate={[Validators.required]}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="col-md-6 mb-3">
                <SelectField
                  options={realEstateTypes}
                  label={strings.addAnnouncementRealEstateTypeLabel}
                  placeholder={strings.addAnnouncementRealEstateTypePLaceholder}
                  name="realEstateType"
                  key="realEstateType"
                  validate={[Validators.required]}
                />
              </div>
            )}
          </div>
        </>
      );
    case categoryNames.CATEGORY_INVESTISSEMENT:
      return (
        <div className="row mt-4">
          <div className="col-md-6 mb-3">
            <SelectField
              options={opportunities}
              label={strings.addAnnouncementOpportunityLabel}
              placeholder={strings.addAnnouncementOpportunityPLaceholder}
              name="opportunity"
              getSelectedValue={handleOpportunityChange}
              validate={[Validators.required]}
            />
          </div>
          {subOpportunities.length > 0 && (
            <div className="col-md-6 mb-3">
              <SelectField
                options={subOpportunities}
                label={strings.addAnnouncementSubOpportunityLabel}
                placeholder={strings.addAnnouncementSubOpportunityPLaceholder}
                name="subOpportunity"
                key="subOpportunity"
                validate={[Validators.required]}
              />
            </div>
          )}
          {incentives.length > 0 && (
            <div className="col-12 mb-3">
              <SelectField
                options={incentives}
                label={strings.addAnnouncementIncentivesLabel}
                placeholder={strings.addAnnouncementIncentivesPlaceholder}
                name="incentives"
                key="incentives"
                validate={[Validators.required]}
              />
            </div>
          )}
        </div>
      );

    default:
      return null;
  }
};

VariableFields.propTypes = VariableFieldsPropTypes;
export default VariableFields;
