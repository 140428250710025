const calculateUserCredit = creditArray => {
  let credits = 0;

  if (!(creditArray && creditArray.length)) return 0;
  creditArray
    .filter(credit => new Date(credit.exp) > new Date())
    .forEach(element => {
      credits += element.amount;
    });

  return credits;
};

export default calculateUserCredit;
