import * as AnnouncmentServices from 'actions/Annonces/services';
import { startLoading, stopLoading } from 'actions/Loading';
import { FETCH_UNVERIVIED_ANNONCES } from 'constants/types';
import _ from 'lodash';
import { errorHandler } from 'helpers/errorHandler';
import { toast } from 'react-toastify';

export const updateGeneralData = (announcementData, announcement) => async dispatch => {
  try {
    dispatch(startLoading());
    if (_.isEmpty(announcement)) throw Error('No selected announcement');
    await AnnouncmentServices.editGeneralData(announcement._id, announcementData);
    toast('Mise a jour effectuée', { className: 'toast-success' });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
  window.location.reload();
};

export const validateAnnounce = annonce => async dispatch => {
  try {
    dispatch(startLoading());
    const newAnnoncesList = await AnnouncmentServices.validateAnnoncement(annonce._id);

    toast('Annonce Validée', { className: 'toast-success' });

    dispatch({ type: FETCH_UNVERIVIED_ANNONCES, payload: newAnnoncesList });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const deleteAnnounce = annonce => async dispatch => {
  try {
    dispatch(startLoading());
    const newAnnoncesList = await AnnouncmentServices.deleteAnnoncement(annonce._id);

    toast('Annonce Supprimé', { className: 'toast-success' });

    dispatch({ type: FETCH_UNVERIVIED_ANNONCES, payload: newAnnoncesList });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const updatePhotosOrder = (announcementData, announcement) => async dispatch => {
  try {
    dispatch(startLoading());

    if (_.isEmpty(announcement)) throw Error('No selected announcement');
    await AnnouncmentServices.editPhotosOrder(announcement._id, announcementData);
    toast('Images rearrangée', { className: 'toast-success' });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
  window.location.reload();
};

export const addPictures = (announcementData, announcement) => async dispatch => {
  try {
    dispatch(startLoading());

    if (_.isEmpty(announcement)) throw Error('No selected announcement');
    await AnnouncmentServices.addPictures(announcement._id, announcementData);
    toast('Images Ajoutée', { className: 'toast-success' });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
  window.location.reload();
};
