import React from 'react';
import calculateUserCredit from 'helpers/calculateUserCredit';

const getColumns = (path, handleAction) => {
  let text;

  switch (path) {
    case '/add-credits':
      text = 'Ajouter des credits';
      break;
    case '/upgrade-role':
      text = 'Rendre Administrateur';
      break;
    case '/downgrade-role':
      text = "Revoquer les droit d'administrateur";
      break;
    case '/unban-user':
      text = "Amnistier l'utilisateur";
      break;
    case '/ban-user':
      text = "Bannir l'utilisateur";
      break;
    default:
      text = 'text';
      break;
  }

  const columns = [
    {
      name: 'Nom',
      selector: 'firstName',
      sortable: true,
    },
    {
      name: 'Prénom',
      selector: 'lastName',
      sortable: true,
    },
    {
      name: 'Téléphone',
      selector: 'phoneNumber',
      sortable: true,
    },
    {
      name: 'Entreprise',
      selector: 'companyName',
      sortable: true,
    },
    {
      name: 'Crédits',
      selector: row => calculateUserCredit(row.credits),
      sortable: true,
    },
    {
      name: 'Action',
      selector: row => calculateUserCredit(row.credits),
      sortable: true,
      center: true,
      cell: row => (
        <button className="btn btn-outline-primary btn-sm " type="button" onClick={handleAction(row.email)}>
          {text}
        </button>
      ),
    },
  ];

  return columns;
};

export default getColumns;
