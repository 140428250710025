import { RESET_RELATED_DATA, SET_RELATED_DATA } from 'constants/types';

const initialState = {
  countries: [],
  activities: [],
  realEstateTypes: [],
  opportunities: [],
  categories: [],
  userTypes: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_RELATED_DATA:
      return { ...state, ...payload };
    case RESET_RELATED_DATA:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
