import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const ExtraDetails = ({ website, phone, email }) => {
  const [shownDetails, setShownDetails] = useState('');

  const addDetail = id => () => {
    setShownDetails(`${shownDetails}${id}`);
  };

  const isDetailShown = id => shownDetails.indexOf(id) >= 0;

  return (
    <div className="d-md-flex justify-content-center">
      {!isDetailShown(1) ? (
        <button onClick={addDetail(1)} className="btn btn-soft-primary mb-2 w-100" type="button">
          Afficher le site web
        </button>
      ) : (
        <a href={website} target="__blank" className="col-md-4 my-auto text-center col-12">
          {website}
        </a>
      )}
      {!isDetailShown(2) ? (
        <button onClick={addDetail(2)} className="btn btn-soft-primary mx-md-2  mb-2 w-100" type="button">
          Afficher le numéro de téléphone
        </button>
      ) : (
        <a href={`tel:${phone}`} className="col-md-4 text-center my-auto col-12">
          {phone}
        </a>
      )}
      {!isDetailShown(3) ? (
        <button onClick={addDetail(3)} className="btn btn-soft-primary mb-2 w-100" type="button">
          Afficher l&apos;email de contact
        </button>
      ) : (
        <a href={`mailto:${email}`} className="col-md-4 text-center my-auto col-12">
          {email}
        </a>
      )}
    </div>
  );
};

ExtraDetails.propTypes = { website: PropTypes.string, phone: PropTypes.string, email: PropTypes.string };
